import React from 'react';

const Navbar = (props) => {
    const { actorId, setActorId, onSubmit } = props;

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit();
    };

    return (
        <form className='navbar-form' onSubmit={handleSubmit}>
            <div className='navbar-form-label-wrapper'>
                <label className='navbar-form-label' htmlFor='navbar-input'>Actor ID</label>
            </div>
            <input
                id='navbar-input'
                className='input input-label'
                type='text'
                value={actorId}
                onChange={(e) => setActorId(e.target.value)}
            />
            <button className='navbar-form-button-wrapper navbar-form-button-wrapper-primary' type='submit'>Submit</button>
        </form>
    );
};

export default Navbar;
