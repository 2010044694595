/**
 * This function helps to download and save the file
 * in the browser once it have the data that we want
 * to save/download
 * @param {string} filename - string filename with format
 * @param {string} data - content to be stored
 * @returns {null} - save/download the file
 */

import { AWS_S3_PROXY_PREFIX, AWS_S3_UPLOAD_PROXY } from '../containers/RiskOps/constants';

const downloadFile = (filename, data, openInNewTab = false) => {
    const element = document.createElement('a');

    element.setAttribute('href', data);
    element.setAttribute('download', filename);

    if (openInNewTab) {
        element.setAttribute('rel', 'noopener noreferrer nofollow');
        element.setAttribute('target', '_blank');
    }

    element.style.display = 'none';

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

/**
 * Downloads a object hosted on a 3rd party resource
 * Note: CORS policy should allow fetching from the requested origin for this to work
 *
 * @param {string} filename The filename of the downloaded object
 * @param {string} fileFormat The file format of the downloaded object
 * @param {string} url The URL where the object is hosted
 * @returns Does not return anything
 */
export const downloadBlobFromUrl = async (filename, fileFormat, url) => {
    try {
        const response = await fetch(url, { method: 'GET' });
        const blob = await response.blob();

        const newBlob = new Blob([blob], { type: fileFormat, charset: 'UTF-8' });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        link.dispatchEvent(new MouseEvent('click'));
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 60);
    } catch (error) {
        window.open(url, '_blank');
        console.error(error);
    }
};

export function getS3UploadUrl(urlFromState) {
    if (!urlFromState) {
        return urlFromState;
    }
    // TODO : Find alternative to get the domain name to proxy to from config : [VEERAKUMAR] https://monorail.pointz.in/p/fi-app/issues/detail?id=47155
    const currentDomain = window.location.origin;
    // then create the proxied url for the url
    // This is needed because agents from federal are behind a VPN with S3 urls blocked
    // hence urls for those roles are being proxied
    const UrlObject = new URL(urlFromState);

    // @example
    // UrlFromState - https://s3.ap-south-1.amazonaws.com/epifi-liveness/liveness_videos/{actor_id}/*
    // UrlObject.origin - https://s3.ap-south-1.amazonaws.com
    // proxiedUrl - https://{sherlock_domain}/AWS_S3_Proxy/epifi-liveness/liveness_videos/{actor_id}/*
    const proxiedUrl = urlFromState.replace(UrlObject.origin, `${currentDomain}${AWS_S3_UPLOAD_PROXY}`);
    return proxiedUrl;
}

export function getProxiedUrl(urlFromState) {
    if (!urlFromState) {
        return urlFromState;
    }
    // TODO : Find alternative to get the domain name to proxy to from config : [VEERAKUMAR] https://monorail.pointz.in/p/fi-app/issues/detail?id=47155
    const currentDomain = window.location.origin;
    // then create the proxied url for the url
    // This is needed because agents from federal are behind a VPN with S3 urls blocked
    // hence urls for those roles are being proxied
    const UrlObject = new URL(urlFromState);

    // @example
    // UrlFromState - https://s3.ap-south-1.amazonaws.com/epifi-liveness/liveness_videos/{actor_id}/*
    // UrlObject.origin - https://s3.ap-south-1.amazonaws.com
    // proxiedUrl - https://{sherlock_domain}/AWS_S3_Proxy/epifi-liveness/liveness_videos/{actor_id}/*
    const proxiedUrl = urlFromState.replace(UrlObject.origin, `${currentDomain}${AWS_S3_PROXY_PREFIX}`);
    return proxiedUrl;
}

export const imageNodeProxyRoute = (url) => {
    if (typeof (url) !== 'string') return url;

    const UrlObject = new URL(url);

    if (UrlObject.hostname === 's3.ap-south-1.amazonaws.com') return getProxiedUrl(url);
    return url;
};

export default downloadFile;
