import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Table } from '../../../../components';
import { selectVkycData, selectCurrentStage, selectCurrentSubStage, selectMeetingLoadingValue } from '../../selectors';
import {
    getCaptureScreenshot, getExtractDocumentDetails, getMatchScore, getPerformClientAction, setCurrentStagePointers,
    logStageProgress,
} from '../../actions';
import {
    CAMERA_ORIENTATION, CLIENT_ACTION, ONBOARIDNG_STAGE_TYPE, VKYCDocumentType, stagePointerPayload,
} from '../../constants';
// eslint-disable-next-line no-unused-vars
import ProgressBar from '../../../../components/ProgressBar';
import { dobFormatter, matchScoreFormatting, nameFormatter } from '../../utils/serverCodeFormatter';
import ImageUtils, { AutoScaleImageViewer } from '../../../../utils/imageUtils';

const EmiratesIdCaptureStep = (props) => {
    const { data, meetingId, setAllAnsweredYes } = props;

    const subStageStep = useSelector(selectCurrentSubStage(meetingId));
    const currentStepNumber = useSelector(selectCurrentStage(meetingId));

    const setSubStageStep = (stepNumber) => {
        dispatch(setCurrentStagePointers(stagePointerPayload(meetingId, currentStepNumber, stepNumber)));
    };

    const [isImageClear, setIsImageClear] = useState();

    const dispatch = useDispatch();
    const vkycData = useSelector(selectVkycData, shallowEqual);
    const meetingLoadingState = useSelector(selectMeetingLoadingValue(meetingId));

    const logUserEvent = (eventName, currentData) => {
        dispatch(logStageProgress({
            message: eventName,
            currentData,
        }));
    };

    const captureOnClick = () => {
        const imageUrl = vkycData?.meetings?.[meetingId]?.screenshot?.imageUrl;
        if (imageUrl) {
            dispatch(getCaptureScreenshot({
                meetingId,
                screenKey: ['meetings', meetingId, 'stages',
                    currentStepNumber, 'stages', subStageStep, data?.stages[subStageStep]?.image_capture?.camera_type],
            }));
        }
        dispatch(getCaptureScreenshot({
            meetingId,
            screenKey: ['meetings', meetingId, 'stages',
                currentStepNumber, 'stages', subStageStep, data?.stages[subStageStep]?.image_capture?.camera_type],
            data: {},
        }));
    };

    const switchCamera = (currentOrientation) => () => {
        dispatch(getPerformClientAction({
            meetingId, clientAction: CLIENT_ACTION.Flip, currentOrientation, screenKey: 'action',
        }));
    };

    const handleFaceMatchSubmit = (answer) => {
        if (answer) {
            setSubStageStep(subStageStep + 1);
        }
    };

    const stepNumber = vkycData?.meetings?.[meetingId]?.stepNumber;

    const switchCameraAndContinue = () => {
        const currentOrientation = vkycData?.meetings?.[meetingId]?.cameraOrientation;
        if (currentOrientation === CAMERA_ORIENTATION.back) {
            switchCamera(CAMERA_ORIENTATION.back)();
        }
    };

    const onClickImageClearYes = () => {
        setIsImageClear(true);
        setSubStageStep(subStageStep + 1);
        const imageObjectFront = vkycData?.meetings?.[meetingId]?.stages?.[stepNumber]?.stages?.[0]?.[CAMERA_ORIENTATION.back];
        const imageObjectBack = vkycData?.meetings?.[meetingId]?.stages?.[stepNumber]?.stages?.[1]?.[CAMERA_ORIENTATION.back];
        if (imageObjectBack && imageObjectFront) {
            dispatch(getExtractDocumentDetails({
                documentType: VKYCDocumentType.EMIRATES_ID,
                imageUrl: imageObjectFront?.imageUrl,
                frontImageIdentifier: imageObjectFront?.imageIdentifier,
                backImageIdentifier: imageObjectBack?.imageIdentifier,
                extractFaceImage: true,
                screenKey: ['meetings', meetingId, 'stages', vkycData?.meetings?.[meetingId]?.stepNumber, 'documentDetails'],
                meetingId,
            }));
            switchCameraAndContinue();
        }
    };

    const emiratesIdFront = vkycData?.meetings?.[meetingId]?.stages?.[stepNumber]?.stages?.[0]?.[CAMERA_ORIENTATION.back]?.imageUrl;
    const maskSensitiveData = (ocrData) => {
        const logData = ocrData;
        logData.form_data.data = '***';
        return logData;
    };
    const getNextComponent = () => {
        const currentStep = data?.stages?.[subStageStep];

        const currentStage = vkycData?.meetings?.[meetingId]?.stages?.[stepNumber];
        const imgUrl = currentStage?.stages?.[subStageStep]?.[currentStep?.image_capture?.camera_type]?.imageUrl;
        const documentDetails = currentStage?.documentDetails;

        const documentImageOne = currentStage?.stages?.[0]?.[CAMERA_ORIENTATION.back];
        // const documentImageTwo = currentStage?.stages?.[3]?.[CAMERA_ORIENTATION.back];

        const faceImage = vkycData?.meetings?.[meetingId]?.stages?.[2]?.[CAMERA_ORIENTATION.front];

        if (!currentStage) {
            logUserEvent('ID verification current stage invalid', currentStage);
        }

        switch (currentStep?.stage_type) {
            case ONBOARIDNG_STAGE_TYPE.IMAGE_CAPTURE:
                if (!currentStep?.image_capture) {
                    logUserEvent('EmirateID step:- IMAGE_CAPTURE data invalid', currentStep?.image_capture);
                } else {
                    logUserEvent('EmirateID card step:- IMAGE_CAPTURE mounted', currentStep?.image_capture);
                }
                if (vkycData?.meetings?.[meetingId]?.cameraOrientation !== currentStep?.image_capture?.camera_type) {
                    return (
                        <div className='vkyc-switch-container'>
                            <div className='vkyc-container-title mb-30'>{currentStep?.image_capture?.instruction}</div>
                            <Button
                                v2
                                primary
                                label='Switch Camera Side'
                                extClasses=''
                                onClick={switchCamera(vkycData?.meetings?.[meetingId]?.cameraOrientation)}
                            />
                        </div>
                    );
                }
                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{currentStep?.image_capture?.instruction}</div>
                        <div className='vkyc-image-container'>
                            <AutoScaleImageViewer
                                url={imgUrl}
                                baseContainerClass='vkyc-image-placeholder'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal vkyc-image-placeholder-hori-button-height'
                                landscapeImageClass='vkyc-image-horizontal'
                                captureOnClick={captureOnClick}
                            />
                            {imgUrl && (
                                <div className='ml-30'>
                                    <div className='sop-step-des'>{currentStep?.image_capture?.post_capture_detailed_instruction}</div>
                                    <div className='frWrapper'>
                                        <Button
                                            primary={isImageClear}
                                            secondary={!isImageClear}
                                            v2
                                            label='Yes'
                                            onClick={onClickImageClearYes}
                                            extClasses='vkyc-step-option'
                                        />
                                        <Button
                                            primary={isImageClear === false}
                                            secondary={isImageClear !== false}
                                            v2
                                            label='No'
                                            onClick={() => { setIsImageClear(false); }}
                                            extClasses='vkyc-step-option'
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );

            case ONBOARIDNG_STAGE_TYPE.FACE_MATCH: {
                const matchScorePercentage = currentStage?.matchScore?.face_image_match_result?.match_score_percent;
                if (!currentStep?.face_match) {
                    logUserEvent('EmirateID step:- FACE_MATCH  data invalid', currentStep?.face_match);
                } else {
                    logUserEvent('EmirateID step:- FACE_MATCH mounted', currentStep?.face_match);
                }
                return (
                    <div className='vkyc-image-container'>
                        <AutoScaleImageViewer
                            url={emiratesIdFront}
                            baseContainerClass='vkyc-image-placeholder mr-30'
                            baseImageClass='vkyc-image'
                            landscapeContainerClass='vkyc-image-placeholder-horizontal'
                            landscapeImageClass='vkyc-image-horizontal'
                        />
                        <div className='vkyc-image-placeholder'>
                            <ImageUtils
                                url={vkycData?.meetings?.[meetingId]?.stages?.[2]?.[CAMERA_ORIENTATION.front]?.imageUrl}
                                containerClass='vkyc-image'
                            />
                        </div>
                        <div className='ml-30'>
                            <div className='mb-30'>
                                <div className='sop-step-des'>Match Score</div>
                                {
                                    !meetingLoadingState && (
                                        <div className='vkyc-match-score-container'>
                                            <div className={`vkyc-match-score-percentage
                                ${matchScorePercentage >= 50
                                            ? 'vkyc-match-score-percentage--green' : 'vkyc-match-score-percentage--red'}
                                }`}
                                            >
                                                {matchScoreFormatting(matchScorePercentage)}%
                                            </div>
                                            <div className='vkyc-match-score-progress'>
                                                <ProgressBar
                                                    number={matchScorePercentage || 0}
                                                    progressBarStyleClassName={
                                                        matchScorePercentage >= 50
                                                            ? 'vkyc-match-score-progress--green' : 'vkyc-match-score-progress--red'
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className='sop-step-des'>{currentStep?.face_match?.description}</div>
                            <div className='frWrapper'>
                                <Button
                                    primary
                                    v2
                                    label='Yes'
                                    onClick={() => {
                                        setAllAnsweredYes(true);
                                        handleFaceMatchSubmit(true);
                                    }}
                                    extClasses='vkyc-step-option'
                                />
                                <Button
                                    secondary
                                    v2
                                    label='Retake'
                                    onClick={() => {
                                        handleFaceMatchSubmit(false);
                                        setSubStageStep(0);
                                    }}
                                    extClasses='vkyc-step-option'
                                />
                            </div>
                        </div>
                    </div>
                );
            }

            case ONBOARIDNG_STAGE_TYPE.OCR_EXTRACTION:
                if (!currentStep?.ocr_extraction) {
                    logUserEvent('EmirateID step:- OCR_EXTRACTION  data invalid', currentStep?.ocr_extraction);
                } else {
                    logUserEvent('EmirateID step:- OCR_EXTRACTION mounted', currentStep?.ocr_extraction);
                }
                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{currentStep?.ocr_extraction?.instruction}</div>
                        <div className='vkyc-image-container'>

                            <AutoScaleImageViewer
                                url={emiratesIdFront}
                                baseContainerClass='vkyc-image-placeholder mr-30'
                                baseImageClass='vkyc-image'
                                landscapeContainerClass='vkyc-image-placeholder-horizontal'
                                landscapeImageClass='vkyc-image-horizontal'
                            />
                            <div className=''>
                                {
                                    !meetingLoadingState && (
                                        <div className=''>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Name</div>
                                                <div className='vkyc-kv-value'>
                                                    {nameFormatter(documentDetails?.document_details?.emirates_id_details?.name || {})}
                                                </div>
                                            </div>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Date of birth</div>
                                                <div className='vkyc-kv-value'>
                                                    {dobFormatter(documentDetails?.document_details?.emirates_id_details?.dob || {})}
                                                </div>
                                            </div>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Document Number</div>
                                                <div className='vkyc-kv-value'>
                                                    {documentDetails?.document_details?.emirates_id_details?.identity_number || '-'}
                                                </div>
                                            </div>
                                            <div className='vkyc-kv-container'>
                                                <div className='vkyc-kv-key'>Emirates ID&apos;s Date of Expiry</div>
                                                <div className='vkyc-kv-value'>
                                                    {dobFormatter(documentDetails?.document_details?.emirates_id_details?.date_of_expiry || {})}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className='frWrapper'>
                                    <Button
                                        primary
                                        v2
                                        label='Continue'
                                        onClick={() => {
                                            dispatch(getMatchScore({
                                                livenessImageIdentifier: faceImage?.imageIdentifier,
                                                documentImageIdentifier: documentImageOne?.imageIdentifier,
                                                meetingId,
                                                documentDetails: documentDetails?.document_details,
                                                screenKey: [
                                                    'meetings',
                                                    meetingId,
                                                    'stages',
                                                    vkycData?.meetings?.[meetingId]?.stepNumber,
                                                    'matchScore',
                                                ],
                                            }));
                                            setSubStageStep(subStageStep + 1);
                                        }}
                                        extClasses='vkyc-step-option'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case ONBOARIDNG_STAGE_TYPE.OCR_VALIDATION:
                if (!currentStep?.ocr_validation) {
                    logUserEvent('EmirateID step:- OCR_VALIDATION  data invalid', maskSensitiveData(currentStep?.ocr_validation));
                } else {
                    logUserEvent('EmirateID step:- OCR_VALIDATION mounted', maskSensitiveData(currentStep?.ocr_validation));
                }
                return (
                    <div className='vkyc-image-layout'>
                        <div className='vkyc-container-title'>{currentStep?.ocr_validation?.instruction}</div>
                        <div className=''>
                            <Table
                                rowDataVersion={2}
                                objKey='header_key'
                                labelData={currentStage?.matchScore?.ocr_details_match_results?.columnData}
                                contentData={currentStage?.matchScore?.ocr_details_match_results?.rowData}
                                // renderActionableColumn={renderActions}
                                prevToken={null}
                                nextToken={null}
                                paginatedClickHandler={() => {}}
                                extClasses={{
                                    container: 'p-20',
                                }}
                            />
                        </div>
                    </div>
                );

            default: return <div>Something went wrong</div>;
        }
    };

    return (
        <div>
            {getNextComponent()}
        </div>
    );
};

export default EmiratesIdCaptureStep;
