/**
 *
 * TreeTraversal - Component to control the tree traversal of a SOP and handle all the Step types we support
 *
 */

import React, {
    memo, useContext,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import Arrow from '../../../assets/images/svgs/arrow-upright.svg';
import Retry from '../../../assets/images/svgs/retry.svg';
import {
    SherlockV2SOPClickedGoBackToPreviousStep,
    SherlockV2SOPClickedRestartFlow,
    SherlockV2SOPClickedGoHome,
    SherlockV2SOPCompleted,
    SherlockV2SOPClickedCompleteCall,
} from '../../../rudderEvents/SherlockV2SOP';
import { Button, LoaderOrError } from '../../../components';
import { pushRudderEvent } from '../../../rudderEvents';
import { UserDetailsContext } from '../../../context';

import { HOME_PATH, SOP_PATH } from '../../AppV2/routes';

import { completeSopFlowAction, delSOPNodeAction, setCallerIdAction, setTicketIdAction } from '../actions';
import { selectSOPNode } from '../selector';
import { STEP_TYPE } from '../constants';

import QuestionStep from '../QuestionStep';
import PictureGuideStep from '../PictureGuideStep';
import InfoDisplayStep from '../InfoDisplayStep';
import FinalStep from '../FinalStep';
import History from '../History';

const TreeTraversal = (props) => {
    const {
        title, getNextNode, currentStep, setCurrentStep, sourceNode, ticketId,
    } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const { sopId } = useParams();
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);

    const nodesData = useSelector(selectSOPNode, shallowEqual);

    // const [tabCloseWindowVisible, setTabCloseWindowVisible] = useState(false);
    // const [time, setTimerStart] = useTimer((3 * TIME_IN_MILLISECONDS) / 1000);

    const {
        loading, err, data: nodes,
    } = nodesData || {};

    const isFinal = (step) => step && step?.stepType === STEP_TYPE.finalStep;

    const getRudderEventData = () => ({
        sopId,
        ticketId,
    });

    const isInfoDisplay = (step) => step && step?.stepType === STEP_TYPE.infoDisplay;

    // a function for getting userResponses based on nodes
    const getUserResponses = (finalNodes) => finalNodes && Array.isArray(finalNodes) && finalNodes.map((node) => {
        const { stepType, step, response: stepResponse } = node || {};
        const response = { step_type: stepType };
        switch (stepType) {
            case STEP_TYPE.question: {
                response.question_response = {
                    question_text: step?.questionText,
                    response_text: stepResponse,
                };
                break;
            }
            case STEP_TYPE.pictureGuide: {
                response.picture_guide_response = {
                    heading_text: step?.title,
                    response_text: 'Viewed',
                };
                break;
            }
            case STEP_TYPE.infoDisplay: {
                response.info_display_response = {
                    heading_text: step?.title,
                    response_text: 'Viewed',
                };
                break;
            }
            case STEP_TYPE.finalStep: {
                const { productCategory } = step || {};
                response.final_step_response = {
                    product_category: {
                        agent: productCategory?.agent,
                        product_category: productCategory?.L1,
                        product_category_details: productCategory?.L2,
                        sub_category: productCategory?.L3,
                        status: productCategory?.status?.value,
                        group: productCategory?.group?.value,
                    },
                };
                break;
            }
            default: {
                return null;
            }
        }
        return response;
    });

    // Renders the step data according to the step type
    const getStepComponent = (stepData) => {
        const { stepType, step } = stepData || {};
        switch (stepType) {
            case STEP_TYPE.question: {
                return <QuestionStep step={step} currentStep={currentStep} getNextNode={getNextNode} />;
            }
            case STEP_TYPE.pictureGuide: {
                return <PictureGuideStep step={step} currentStep={currentStep} getNextNode={getNextNode} />;
            }
            case STEP_TYPE.infoDisplay: {
                return (
                    <InfoDisplayStep
                        step={step}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                        getNextNode={getNextNode}
                    />
                );
            }
            case STEP_TYPE.finalStep: {
                return <FinalStep step={step} currentStep={currentStep} />;
            }
            default: {
                return null;
            }
        }
    };

    const handleOnCompleteCall = () => {
        pushRudderEvent(SherlockV2SOPClickedCompleteCall, agentInfo.emailId, getRudderEventData());

        const callAction = () => new Promise((resolve, reject) => {
            try {
                dispatch(completeSopFlowAction({
                    screenKey: ['tree'], sopId, userResponses: getUserResponses(nodes), ticketId, resolve,
                }));
            } catch (e) {
                reject(e);
            }
        });

        callAction().then(() => {
            // This is commented out because the we will not be closing the Sherlock V2 from now on.
            // if (window.opener && window.opener.location.origin === window.location.origin) {
            //     pushRudderEvent(SherlockV2SOPCompleted, agentInfo.emailId, getRudderEventData());
            //     setTabCloseWindowVisible(true);
            //     setTimerStart(true);
            //     setTimeout(() => {
            //         postMessageEvent(window.opener, { closeSherlockV2: true }, window.opener.location.origin);
            //         setTabCloseWindowVisible(false);
            //         // Keeping a timeout here so that window close happens first and
            //         // if it doesn't happen, website redirects to default ticket path.
            //         setTimeout(() => {
            //             history.replace(DEFAULT_TICKET_PATH);
            //         }, 1);
            //     }, (3 * TIME_IN_MILLISECONDS));
            // } else {
            //     history.replace(DEFAULT_TICKET_PATH);
            // }
            pushRudderEvent(SherlockV2SOPCompleted, agentInfo.emailId, getRudderEventData());
            dispatch(setTicketIdAction({ path: ['ticketId'], value: '' }));
            dispatch(setCallerIdAction({ path: ['callerId'], value: '' }));
            history.push(HOME_PATH);
        });
    };

    return (
        <React.Fragment>
            <div>
                <div className='frcsbWrapper'>
                    <div className='sop-heading'>{title}</div>
                    <Button
                        link
                        label='Restart Flow'
                        onClick={() => {
                            pushRudderEvent(SherlockV2SOPClickedRestartFlow, agentInfo.emailId, getRudderEventData());
                            setCurrentStep(() => (-1));
                            getNextNode(sourceNode, true);
                        }}
                    />
                </div>
                <div className={`sop-traversal ${loading ? 'sop-traversal-loading' : ''}`}>
                    <div className='sop-cc p-30'>
                        <div className='frfssbWrapper'>
                            {
                                !isFinal(nodes && nodes[currentStep]) && (
                                    <Button
                                        link
                                        label={<img src={Arrow} className='sop-step-back-img' alt='Back Arrow' />}
                                        extClasses='mb-15 p-0'
                                        onClick={() => {
                                            pushRudderEvent(SherlockV2SOPClickedGoBackToPreviousStep, agentInfo.emailId, getRudderEventData());
                                            if (currentStep > 0) {
                                                setCurrentStep(currentStep - 1);
                                            } else {
                                                history.push(`${SOP_PATH}`);
                                            }
                                            dispatch(delSOPNodeAction({ noOfElements: 1 }));
                                        }}
                                    />
                                )
                            }
                            {
                                isInfoDisplay(nodes && nodes[currentStep]) && (
                                    <Button
                                        link
                                        label={<img src={Retry} className='sop-step-back-img' alt='Retry' />}
                                        extClasses='mb-15 p-0'
                                        onClick={() => {
                                            const { step } = (nodes && nodes[currentStep] && nodes[currentStep]) || {};
                                            if (step) {
                                                getNextNode(
                                                    step?.nodeId,
                                                    false,
                                                    { isSecondRpcCall: true, rpcsToBeCalled: step?.errorRpcs },
                                                );
                                                setCurrentStep((stepIndex) => stepIndex - 1);
                                            }
                                        }}
                                    />
                                )
                            }
                        </div>
                        <div className='p-30'>
                            {nodes && Array.isArray(nodes) && nodes[currentStep] && getStepComponent(nodes[currentStep])}
                        </div>
                        {
                            currentStep > 0 && (
                                <React.Fragment>
                                    <div className='line' />
                                    <History
                                        nodes={nodes}
                                        isFinal={isFinal(nodes && nodes[currentStep])}
                                        setCurrentStep={setCurrentStep}
                                        getNextNode={getNextNode}
                                        sopId={sopId}
                                        ticketId={ticketId}
                                    />
                                </React.Fragment>
                            )
                        }
                        {
                            !isFinal(nodes && nodes[currentStep]) ? (
                                <React.Fragment>
                                    <div className='line' />
                                    <div className='p-30 frcWrapper'>
                                        <div className='mr-16'>Selected the wrong SOP?</div>
                                        <Button
                                            v2
                                            primary
                                            label='Go Home'
                                            onClick={() => {
                                                pushRudderEvent(SherlockV2SOPClickedGoHome, agentInfo.emailId, getRudderEventData());
                                                history.push(`${SOP_PATH}`);
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className='frcfeWrapper'>
                                    <Button
                                        v2
                                        primary
                                        extClasses='ml-15'
                                        label='Complete the call'
                                        onClick={handleOnCompleteCall}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <LoaderOrError loading={loading} errorLabel={err} parentNode='.sop-traversal' />
            {/* This is commented out because the we will not be closing the Sherlock V2 from now on. */}
            {/* <Modal visible={tabCloseWindowVisible}>
                <div className='frcseWrapper'>
                    <div className='loader' />
                    <div>Ticket Details Updated. Closing Window in {time} sec{time > 1 ? 's' : ''}.</div>
                </div>
            </Modal> */}
        </React.Fragment>
    );
};

TreeTraversal.propTypes = {
    title: PropTypes.string,
    getNextNode: PropTypes.func.isRequired,
    currentStep: PropTypes.number,
    setCurrentStep: PropTypes.func.isRequired,
    sourceNode: PropTypes.string,
};

TreeTraversal.defaultProps = {
    title: '',
    currentStep: -1,
    sourceNode: '',
};

export default memo(TreeTraversal);
