// The above comment must be removed while pushing the code to production

import {
    all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';
import {
    CAPTURE_SCREENSHOT, CONCLUDE_CALL, END_CALL_ENDPOINT, EXTRACT_DOCUMENT_DATA,
    GENERATE_REPORT_ENDPOINT, INITIATE_CALL,
    MATCH_SCORE_ENDPOINT, ONBOARDING_STAGES, PERFORM_CLIENT_ACTION, RECORDING_UPLOAD_ENDPOINT,
    GET_AVAILABLE_CALLS_COUNT,
    LOG_MESSAGE_ENDPOINT,
    HEALTH_CHECK,
} from '../../api/routes';
import {
    CAMERA_ORIENTATION, CLIENT_ACTION,
    GET_CAPTURE_SCREENSHOT, GET_CONCLUDE_VKYC_CALL, GET_END_VKYC_CALL, GET_EXTRACT_DOCUMENT_DETAILS,
    GET_GENERATE_VKYC_REPORT, GET_INITIATE_VKYC_CALL, GET_MATCH_SCORE, GET_ONBOARDING_STAGES, GET_PERFORM_CLIENT_ACTION,
    UPLOAD_SCREEN_RECORDING, GET_VKYC_AVAILABLE_CALLS_COUNT, SET_VKYC_AVAILABLE_CALL_COUNT,
    FLOW_PROGRESS,
    LOG_VKYC_STAGE_PROGRESS,
    VKYC_STAGES,
    GET_HEALTH_CHECK,
} from './constants';
import {
    errEndVKYCCall,
    errGenerateVKYCReport, errInitiateCall, errMatchScore, errOnboardingStages,
    errPerformClientAction, errUploadScreenRecording, setCaptureScreenshot,
    setGenerateVKYCReport, setInitiateCall,
    setMatchScore,
    setOnboardingStages,
    setPerformClientAction,
    setTitle,
    setUploadScreenRecording,
    setVKYCCameraOrientation,
    setRejectionReasons,
    setCurrentStep,
    setMeetingLoaderState,
    setConcludeCall,
    errConcludeCall,
    setErrorViewAction,
    setHealthCheck,
} from './actions';
import { fetchFile } from './utils/fileStore';
import { getS3UploadUrl } from '../../utils/downloadFile';
import { getErrComponent } from '../../utils/errFormatter';

// Uploads KYC video content to S3 bucket
const uploadKycContent = async (meetingId, s3Url) => {
    const file = fetchFile(meetingId);
    const formData = new FormData();
    formData.append('files', file);

    const res = await fetch(getS3UploadUrl(s3Url), {
        method: 'PUT',
        body: file, // Send raw file instead of FormData
        headers: {
            ContentType: 'video/mp4', // Specify content type for video upload
        },
    });

    // Extract response status details
    const {
        status, ok, url,
    } = res;

    let responseMessage = ' ';

    // Handle error case by getting error text from response
    if (!ok) {
        const errorText = await res.text();
        responseMessage = `: ${errorText}`;
    }

    // Return upload result with status, message, response url and size of the file which was supposed to be uploaded
    return {
        ok,
        message: ` ${status}${responseMessage}.`,
        size: file?.size,
        proxiedUrl: url,
    };
};

function* getInitiateCall(action) {
    const { ticketId } = action.data;
    const queryString = getQueryStringFromObject({ ticketId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${INITIATE_CALL}?${queryString}`,
        );
        yield put(setInitiateCall({ key: 'data', value: response }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errInitiateCall({ err: getErrMsg(e) }));
        yield put(setCurrentStep({ progress: FLOW_PROGRESS.END_CALL_FAILED }));
    }
}

function* startHealthCheck(action) {
    const { meetingId } = action.data;
    const queryString = getQueryStringFromObject({ meetingId });
    try {
        yield put(setMeetingLoaderState({ meetingId, loading: true }));
        yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${HEALTH_CHECK}?${queryString}`,
        );
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
        yield put(setHealthCheck({ data: 'PASSED' }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
        // eslint-disable-next-line no-promise-executor-return
        const timeOut = () => new Promise((resolve) => setTimeout(resolve, 3000));
        yield call(timeOut);
        yield put(setCurrentStep({ progress: FLOW_PROGRESS.END_CALL_FAILED }));
    }
}

function* getConcludeCall(action) {
    const {
        Data, meetingId, s3Url, screenKey,
    } = action?.data || {};

    const requestBody = {
        meetingId,
        Data,
    };

    try {
        if (s3Url) {
            yield call(
                logStage,
                {
                    data: {
                        message: 'Uploading VKYC video',
                        meetingId,
                        stageName: VKYC_STAGES.VKYC_REPORT,
                        subStageType: 'Video Upload',
                    },
                },
            );
            const {
                ok, message, size, proxiedUrl,
            } = yield call(uploadKycContent, meetingId, s3Url);
            yield call(
                logStage,
                {
                    data: {
                        message: `VKYC video ${ok ? 'uploaded' : 'upload failed'}.${message} Video Size: ${size} Bytes`,
                        url: s3Url,
                        proxiedUrl,
                        meetingId,
                        stageName: VKYC_STAGES.VKYC_REPORT,
                        subStageType: 'Video Upload',
                    },
                },
            );
            if (!ok) {
                throw new Error(message);
            }
        }
        yield call(
            logStage,
            {
                data: {
                    message: 'Concluding VKYC call',
                    meetingId,
                    stageName: VKYC_STAGES.VKYC_REPORT,
                    subStageType: 'Conclude Call',
                },
            },
        );
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            CONCLUDE_CALL,
            requestBody,
        );
        yield call(
            logStage,
            {
                data: {
                    message: 'Concluded VKYC call',
                    meetingId,
                    stageName: VKYC_STAGES.VKYC_REPORT,
                    subStageType: 'Conclude Call',
                },
            },
        );
        yield put(setConcludeCall({ screenKey }));
        yield put(setCurrentStep({ progress: FLOW_PROGRESS.END_CALL_SUCCESS }));
        yield put(setInitiateCall({ key: 'data', value: { meetingId: null, recordingUploadURL: '' } }));
        yield put(setTitle({ title: 'VKYC Flow' }));
    } catch (e) {
        const err = getErrLabel(e);
        yield put(errConcludeCall({ screenKey, err: getErrMsg(e) }));
        yield call(
            logStage,
            {
                data: {
                    message: `Conclude VKYC call failed: ${err}`,
                    meetingId,
                    stageName: VKYC_STAGES.VKYC_REPORT,
                    subStageType: 'Conclude Call',
                },
            },
        );
        toastify('Please try again and if unable to proceed then reach out admin', 'error', true, { position: 'top-center' });
    }
}

function* captureScreenshot(action) {
    const { meetingId, screenKey } = action.data;
    const queryString = getQueryStringFromObject({ id: meetingId });

    try {
        yield put(setMeetingLoaderState({ meetingId, loading: true }));
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${CAPTURE_SCREENSHOT}?${queryString}`,
        );
        yield put(setCaptureScreenshot({ screenKey, value: response }));
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
    }
}

function* performClientAction(action) {
    const { meetingId, clientAction, currentOrientation } = action.data;
    const queryString = getQueryStringFromObject({ meetingId, clientAction });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${PERFORM_CLIENT_ACTION}?${queryString}`,
        );
        yield put(setPerformClientAction({ screenKey: 'action', value: response }));
        if (clientAction === CLIENT_ACTION.Flip) {
            let orientation;
            if (currentOrientation === CAMERA_ORIENTATION.back) {
                orientation = CAMERA_ORIENTATION.front;
            } else {
                orientation = CAMERA_ORIENTATION.back;
            }
            yield put(setVKYCCameraOrientation({ screenKey: ['meetings', meetingId], value: { cameraOrientation: orientation } }));
        }
        toastify('Action performed successfully', 'success', true, { position: 'top-center' });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errPerformClientAction({ screenKey: 'action', err: getErrMsg(e) }));
    }
}

function* getStages(action) {
    const { meetingId } = action.data;
    const queryString = getQueryStringFromObject({ meetingId });

    try {
        yield put(setMeetingLoaderState({ meetingId, loading: true }));
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${ONBOARDING_STAGES}?${queryString}`,
        );
        response.stepNumber = 0;
        response.subStepNumber = 0;
        response.cameraOrientation = CAMERA_ORIENTATION.front;
        yield put(setOnboardingStages({ screenKey: ['meetings', meetingId], value: response }));
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errOnboardingStages({ screenKey: ['meetings', meetingId], err: getErrMsg(e) }));
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
    }
}

function* getMatchScore(action) {
    const {
        screenKey, meetingId,
    } = action.data;
    try {
        yield put(setMeetingLoaderState({ meetingId, loading: true }));
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            `${MATCH_SCORE_ENDPOINT}`,
            action.data,
        );
        yield put(setMatchScore({ screenKey, value: response }));
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
    } catch (e) {
        const { errorView, errorLabel } = getErrComponent(e);
        yield put(setErrorViewAction({ data: errorView }));
        yield put(errMatchScore({ screenKey, err: errorLabel }));
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
    }
}

function* extractDetailsFromDocument(action) {
    const {
        documentType, imageUrl, frontImageIdentifier, backImageIdentifier, extractFaceImage, screenKey, meetingId,
    } = action.data;
    const queryString = getQueryStringFromObject({
        documentType, imageUrl, frontImageIdentifier, backImageIdentifier, extractFaceImage, meetingId,
    });

    try {
        yield put(setMeetingLoaderState({ meetingId, loading: true }));
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${EXTRACT_DOCUMENT_DATA}?${queryString}`,
        );
        yield put(setOnboardingStages({ screenKey, value: response }));
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
    } catch (e) {
        const { errorView, errorLabel } = getErrComponent(e);
        yield put(setErrorViewAction({ data: errorView }));
        yield put(errOnboardingStages({ screenKey, err: errorLabel }));
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
    }
}

function* uploadScreenRecord(action) {
    const { formData, meetingId } = action.data;
    try {
        const response = yield call([clientApiWrapper, clientApiWrapper.fileUploadByFormData], 'PUT', `${RECORDING_UPLOAD_ENDPOINT}`, formData, {});

        yield put(setUploadScreenRecording({ screenKey: ['meetings', meetingId, 'recording'], value: response }));
    } catch (error) {
        const err = getErrLabel(error);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errUploadScreenRecording({ screenKey: ['meetings', meetingId, 'recording'], err: getErrMsg(err) }));
    }
}

function* getEndVKYCCall(action) {
    const {
        meetingId, isAccepted, screenKey, flowStatus, s3Url, Data,
    } = action.data;

    const requestBody = { meetingId, isAccepted };

    try {
        yield put(setMeetingLoaderState({ meetingId, loading: true }));

        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            END_CALL_ENDPOINT,
            requestBody,
        );

        // this conclude api will be called for force upload only.

        if (Data) {
            yield put({
                type: GET_CONCLUDE_VKYC_CALL,
                data: {
                    screenKey: ['meetings', meetingId],
                    meetingId,
                    Data,
                    s3Url,
                },
            });
        }

        if (flowStatus) yield put(setCurrentStep({ progress: flowStatus }));
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errEndVKYCCall({ screenKey, err: getErrMsg(e) }));
        yield put(setMeetingLoaderState({ meetingId, loading: false }));
    }
}

function* getGenerateVKYCReport(action) {
    const { questionAnswers, meetingId, screenKey } = action.data;
    const requestBody = { meetingId, questionAnswers };

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            GENERATE_REPORT_ENDPOINT,
            requestBody,
        );
        yield put(setGenerateVKYCReport({ screenKey, value: response }));
        yield put(setRejectionReasons(response?.rejection_categories));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errGenerateVKYCReport({ screenKey, err: getErrMsg(e) }));
    }
}

function* getAvailableCallsCount() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            GET_AVAILABLE_CALLS_COUNT,
        );
        yield put({ type: SET_VKYC_AVAILABLE_CALL_COUNT, data: response?.waiting_calls });
    } catch (e) {
        yield put({ type: SET_VKYC_AVAILABLE_CALL_COUNT, data: 0 });
    }
}

function* logStage(action) {
    try {
        const {
            stageName, subStageType, message, meetingId, ...rest
        } = action.data;
        const requestBody = {
            message,
            meta: {
                ...((stageName || subStageType) && { stage: `${stageName} : ${subStageType}` }),
                ...(meetingId && { messageId: meetingId }), // Add messageId only if meetingId is not null
                flow: 'InHouseVKYC',
                ...rest,
            },
        };
        yield call(
            [clientApiWrapper, clientApiWrapper.post],
            LOG_MESSAGE_ENDPOINT,
            requestBody,
        );
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
    }
}

export default function* ScriptsSaga() {
    yield all(
        [
            yield takeLatest(GET_INITIATE_VKYC_CALL, getInitiateCall),
            yield takeLatest(GET_HEALTH_CHECK, startHealthCheck),
            yield takeLatest(GET_CONCLUDE_VKYC_CALL, getConcludeCall),
            yield takeLatest(GET_CAPTURE_SCREENSHOT, captureScreenshot),
            yield takeLatest(GET_PERFORM_CLIENT_ACTION, performClientAction),
            yield takeLatest(GET_ONBOARDING_STAGES, getStages),
            yield takeLatest(UPLOAD_SCREEN_RECORDING, uploadScreenRecord),
            yield takeLatest(GET_EXTRACT_DOCUMENT_DETAILS, extractDetailsFromDocument),
            yield takeLatest(GET_MATCH_SCORE, getMatchScore),
            yield takeLatest(GET_END_VKYC_CALL, getEndVKYCCall),
            yield takeLatest(GET_GENERATE_VKYC_REPORT, getGenerateVKYCReport),
            yield takeLatest(GET_VKYC_AVAILABLE_CALLS_COUNT, getAvailableCallsCount),
            yield takeLatest(LOG_VKYC_STAGE_PROGRESS, logStage),
        ],
    );
}
