/**
 *
 * Profile - Component to show all the available SOP .
 *
 */

import React, { useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import SegmentedTabView from '../../../../components/SegmentedTabView';
import {
    // eslint-disable-next-line no-unused-vars
    ActionDialog, Button, ErrorView, KVPairInfo, LoaderOrError, Modal, Table,
} from '../../../../components';
import { pushRudderEvent, RudderEvent } from '../../../../rudderEvents';
import { UserDetailsContext } from '../../../../context';
import { getQueryStringFromObject } from '../../../../utils';

import { HOME_PATH, SEARCH_PATH } from '../../../AppV2/routes';

import {
    getActivitiesAction, getActivityDetailsAction, getAuthFactor, getIssueCategoryDetailsAction,
    getRecentQueriesAction, setTicketIdAction, getUserTabsAction, getUserTabDetailsAction,
} from '../../actions';
import {
    selectTicketId, selectUserActivities, selectUserQueries, selectUserTabs, selectUserTabDetails,
} from '../../selector';

const Profile = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { basicInfo: agentInfo } = useContext(UserDetailsContext);

    const ticketId = useSelector(selectTicketId, shallowEqual);
    const userActivities = useSelector(selectUserActivities, shallowEqual);
    const userQueries = useSelector(selectUserQueries, shallowEqual);
    const userTabTitles = useSelector(selectUserTabs, shallowEqual);
    const tabDetails = useSelector(selectUserTabDetails, shallowEqual);

    const { ticketId: ticketIdParam } = useParams();

    const [loadingText, setLoadingText] = useState('');
    const [selectedTab, setActiveTabName] = useState('');
    // const [openedActionsCardId, setOpenedActionsCardId] = useState('');
    const [showActivityDetailsModal, setShowActivityDetailsModal] = useState(false);

    const {
        loading: loadingDetails, err: errDetails, data: userTabDetails,
    } = tabDetails || {};

    const {
        loading: loadingTabs, err: errTabs, data: userTabs,
    } = userTabTitles || {};

    const {
        loading: loadingActivities, err: errActivities, data: dataActivities, issueCategoryDetails, activityDetails,
    } = userActivities || {};

    const {
        loading: loadingIssueCategory, err: errIssueCategory,
    } = issueCategoryDetails || {};

    const {
        loading: loadingActivityDetails, err: errActivityDetails, data: dataActivityDetails,
    } = activityDetails || {};

    const {
        loading: loadingQueries, err: errQueries, data: dataQueries,
    } = userQueries || {};

    const { activityDetails: activities, prev, screen } = dataActivities || {};

    const { title, day_wise_list: dayWiseList } = dataQueries || {};

    const loading = loadingDetails || loadingActivities || loadingIssueCategory || loadingActivityDetails || loadingQueries || loadingTabs;
    const err = errDetails || errActivities || errIssueCategory || errActivityDetails || errTabs
        || (errQueries && typeof errQueries === 'string' ? errQueries : '');

    const setActiveTab = (tabName) => {
        setActiveTabName(tabName);
        dispatch(getUserTabDetailsAction({ ticketId, tabName }));
    };

    const callAction = (dispatchFunction) => new Promise((resolve, reject) => {
        try {
            dispatchFunction(resolve, reject);
        } catch (e) {
            reject(e);
        }
    });

    useEffect(() => {
        if (!ticketIdParam || ticketIdParam === '0') {
            history.push(HOME_PATH);
            return;
        }
        if (ticketIdParam !== `${ticketId}`) {
            dispatch(setTicketIdAction({ path: ['ticketId'], value: ticketIdParam }));
        }
    }, [ticketIdParam]);

    useEffect(() => {
        if (userTabs && userTabs.available_tabs.length > 0) {
            const firstTab = userTabs.available_tabs[0];
            setActiveTab(firstTab);
        }
    }, [userTabs]);

    const getUserTabs = (resolve, reject) => (
        dispatch(getUserTabsAction({
            screenKey: ['userTabs'], ticketId, resolve, reject, clearData: true,
        }))
    );

    const getRecentActivity = (resolve, reject) => (
        dispatch(getActivitiesAction({
            screenKey: ['userActivities'], ticketId, resolve, reject, clearData: true,
        }))
    );

    const getRecentQueries = (resolve, reject) => (
        dispatch(getRecentQueriesAction({
            ticketId, resolve, reject, clearData: true,
        }))
    );

    useEffect(() => {
        if (ticketId && ticketIdParam === `${ticketId}`) {
            setLoadingText('Connecting to Ticket...');
            callAction((resolve, reject) => dispatch(getAuthFactor({
                ticketId, resolve, reject, isInsensitiveAuth: true,
            })))
                .then(() => {
                    setLoadingText(
                        'Fetching Customer Details, Recent Activity and Recent User queries...',
                    );
                    return Promise.all([
                        callAction(getRecentActivity),
                        callAction(getRecentQueries),
                        callAction(getUserTabs),
                    ].map((promise) => promise.catch((error) => new Error(error))));
                })
                .then(() => {
                    pushRudderEvent(RudderEvent.SherlockV2.ViewedSherlockV2UserDetailsPage, agentInfo.emailId, { ticketId });
                    setLoadingText('');
                })
                .catch((error) => {
                    setLoadingText(`${error}${error && '. '}Redirecting to home.`);
                    setTimeout(() => {
                        history.push(HOME_PATH);
                        setLoadingText('');
                    }, 1000);
                });
        }
    }, [ticketId]);

    /* commented out the mutiple actions handling code as it is not required for now */
    // const handleOnActionClick = (action, activity) => {
    //     switch (action.deeplink.screen_name) {
    //         case 'SCREEN_NAME_RECENT_ACTIVITY_VIEW_DETAILS': {
    //             setLoadingText('Fetching Activity Details...');
    //             callAction((resolve) => dispatch(getActivityDetailsAction({
    //                 screenKey: ['userActivities', 'activityDetails'], ticketId, meta: activity?.meta, resolve,
    //             }))).then(() => setShowActivityDetailsModal(true))
    //                 .finally(() => setLoadingText(''));
    //             break;
    //         }
    //         default: {
    //             console.log("🚀 ~ handleOnActionClick ~ action:", action)
    //             break;
    //         }
    //     }
    // }

    const renderActions = (item) => (
        <div className='frccWrapper'>
            <div className='p-5'>
                <Button
                    v2
                    primary
                    label='Select'
                    extClasses='sop-table-cta'
                    onClick={() => {
                        setLoadingText('Fetching Issue Category...');
                        pushRudderEvent(RudderEvent.SherlockV2.SelectedSherlockV2RecentActivity, agentInfo.emailId, { activity: item?.meta });
                        callAction(
                            (resolve) => dispatch(getIssueCategoryDetailsAction({
                                screenKey: ['userActivities', 'issueCategoryDetails'], meta: item?.meta, resolve, clearData: true,
                            })),
                        ).then((resp) => {
                            const { l1, l2, l3 } = resp || {};
                            history.push({
                                pathname: SEARCH_PATH,
                                search: getQueryStringFromObject({
                                    l1, l2, l3, type: 'SEARCH_RESULT_TYPE_SOP',
                                }),
                            });
                        }).finally(() => setLoadingText(''));
                    }}
                />
            </div>
            <div className='p-5'>
                <Button
                    v2
                    secondary
                    label='View Details'
                    extClasses='sop-table-cta'
                    onClick={() => {
                        setLoadingText('Fetching Activity Details...');
                        callAction((resolve) => dispatch(getActivityDetailsAction({
                            screenKey: ['userActivities', 'activityDetails'], ticketId, meta: item?.meta, resolve, clearData: true,
                        }))).then(() => {
                            setShowActivityDetailsModal(true);
                            pushRudderEvent(
                                RudderEvent.SherlockV2.ViewedSherlockV2RecentActivityDetails,
                                agentInfo.emailId,
                                { activity: item?.meta },
                            );
                        }).finally(() => setLoadingText(''));
                    }}
                />
            </div>
            {/* commented out the mutiple actions rendering code as it is not required for now  */}
            {/* {
                item?.actions && Array.isArray(item?.actions) && item?.actions?.length > 0 && (
                    <ActionDialog
                        item={item}
                        key={item?.id || index}
                        extClasses={{
                            container: 'sop-table-cta-dropdown p-20',
                        }}
                        openedActionsCardId={openedActionsCardId}
                        setOpenedActionsCardId={setOpenedActionsCardId}
                    >
                        <div className='fccWrapper'>
                            {
                                item?.actions?.map((action) => (
                                    <button
                                        key={action?.id}
                                        type='button'
                                        className='p-5'
                                        onClick={() => {
                                            setOpenedActionsCardId('');
                                            handleOnActionClick(action, item);
                                        }}

                                    >
                                        {action?.label}
                                    </button>
                                ))
                            }
                        </div>
                    </ActionDialog>
                )
            } */}
        </div>
    );

    return (
        <React.Fragment>
            <div>
                <div className='sop-heading'>User Details</div>
                <div className='sop-cc sop-box'>
                    <SegmentedTabView
                        tabsTitles={userTabs}
                        activeTab={selectedTab}
                        tabDetails={userTabDetails?.tab_details}
                        setActiveTab={setActiveTab}
                        extClasses={{
                            container: 'segmented-tab-container',
                            tabContainer: 'segmented-tab-tabs-container',
                            tabContent: 'segmented-tab-content',
                            tabActive: 'segmented-tab-active',
                            tabInactive: 'segmented-tab-inactive',
                        }}
                    />
                </div>
            </div>

            <div className='frWrapper sop-table-wrapper'>
                {
                    (activities || screen) && (
                        <div
                            className={
                                (dataQueries || (errQueries && typeof errQueries === 'object')) ? 'f07Wrapper' : 'w-100'
                            }
                        >
                            <div className='sop-heading'>Recent Activity</div>
                            <Table
                                v2
                                infiniteScroll
                                rowDataVersion={2}
                                objKey='header_key'
                                labelData={activities?.columnData}
                                contentData={activities?.rowData}
                                renderActionableColumn={renderActions}
                                infiniteScrollPaginationToken={prev}
                                errLabel={
                                    // Check if the current screen is either LANDING_PAGE (2) or AUTH_EXPIRED_SCREEN (6)
                                    // and if there are no activities available. If so, display an error message.
                                    (screen === 2 || screen === 6) && !activities
                                        ? 'Current level of authorization is not enough to view this table or Authentication has expired' : ''
                                }
                                nsrLabel='No Recent Activities Found for the user in last 3 months'
                                paginatedClickHandler={({ token }) => {
                                    setLoadingText('Fetching Recent Activity...');
                                    callAction((resolve) => dispatch(getActivitiesAction({
                                        screenKey: ['userActivities'], ticketId, prevToken: token, resolve, clearData: true,
                                    }))).finally(() => setLoadingText(''));
                                }}
                                extClasses={{
                                    container: 'sop-cc sop-table',
                                    headerCr: 'sop-table-header',
                                    contentCr: 'sop-table-cont',
                                    rowCr: 'sop-table-row',
                                    infiniteScrlBtn: 'sop-table-cta',
                                }}
                            />
                        </div>
                    )
                }
                {
                    (dataQueries || (errQueries && typeof errQueries === 'object')) && (
                        <div className='f03Wrapper'>
                            <div className='sop-heading'>{title || 'Search History'}</div>
                            <div className='sop-cc-v2'>
                                {
                                    dayWiseList && Array.isArray(dayWiseList) && dayWiseList.length > 0 && (
                                        dayWiseList?.map((queriesData, index) => (
                                            <React.Fragment>
                                                {
                                                    index !== 0 && <div className='line-thick' />
                                                }
                                                <div className='p-20'><strong>{queriesData?.date}</strong></div>
                                                {
                                                    queriesData?.query && queriesData?.query.length > 0 && (
                                                        queriesData?.query.map((queryData) => (
                                                            <React.Fragment>
                                                                <div className='line' />
                                                                <div className='frcsbWrapper sop-table-row'>
                                                                    <img src={queryData?.left_icon_url} alt='icon' className='p-20' />
                                                                    <div className='p-5 w-100'>
                                                                        <div className='sop-title'>{queryData?.title}</div>
                                                                        <div className='sop-title-sub p-5'>{queryData?.sub_title}</div>
                                                                    </div>
                                                                    <Button
                                                                        v2
                                                                        primary
                                                                        label={queryData?.cta?.label}
                                                                        extClasses='sop-table-cta'
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            pushRudderEvent(
                                                                                RudderEvent.SherlockV2.SelectedSherlockV2RecentQuery,
                                                                                agentInfo.emailId,
                                                                                { query: queryData },
                                                                            );
                                                                            const {
                                                                                product_category: l1, product_category_details: l2, sub_category: l3,
                                                                            } = queryData?.issue_category || {};
                                                                            history.push({
                                                                                pathname: SEARCH_PATH,
                                                                                search: getQueryStringFromObject({
                                                                                    l1, l2, l3, type: 'SEARCH_RESULT_TYPE_SOP',
                                                                                }),
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                        ))
                                                    )
                                                }
                                            </React.Fragment>
                                        ))
                                    )
                                }
                                <ErrorView
                                    errorObject={errQueries}
                                    extClasses={{
                                        inline: {
                                            container: 'frcWrapper sop-err-cont',
                                            text: 'sop-err w-100',
                                            button: 'sop-err-button',
                                        },
                                    }}
                                    isButtonV2
                                    clickOptions={{
                                        onPrimaryButtonClick: () => {
                                            setLoadingText('Fetching Recent Queries...');
                                            callAction(getRecentQueries)
                                                .catch((error) => {
                                                    console.error('Recent Queries Error:', error);
                                                })
                                                .finally(() => {
                                                    setLoadingText('');
                                                });
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    )
                }

            </div>
            <Modal
                visible={showActivityDetailsModal && !loadingActivityDetails}
                modalStyles={{
                    content: {
                        maxHeight: 700,
                        width: 800,
                    },
                }}
                contentLabel='Activity Details Modal'
            >
                <div className='recent-activity-modal__title'>Activity Details</div>
                {
                    dataActivityDetails?.map((item) => (
                        <div className='recent-activity-section__container'>
                            <div className='recent-activity-section__title'>{item.title}</div>
                            <KVPairInfo
                                v2
                                data={item.label_values}
                            />
                        </div>
                    ))
                }
                <Button
                    primary
                    extClasses='txns-modal-bwr'
                    label='Close'
                    onClick={() => setShowActivityDetailsModal(!showActivityDetailsModal)}
                />
            </Modal>
            <LoaderOrError loading={loading || loadingText} errorLabel={err} loadingText={loadingText} />
        </React.Fragment>
    );
};

export default Profile;
