/**
 * @file SegmentedTabView
 * SegmentedTabView component is a reusable component that displays a segmented tab view with a list of tabs and their respective content.
 * Tapping on a tab will display the content of that tab.
 */

import React from 'react';
import classNames from 'classnames';
import DataGridView from '../DataGridView';
import './style.scss';

const SegmentedTabView = (props) => {
    const {
        tabsTitles, tabDetails, activeTab, setActiveTab, extClasses = {},
    } = props;
    const containerClass = classNames(extClasses && extClasses.container);
    const tabContainerClass = classNames(extClasses && extClasses.tabContainer);
    const tabActiveClass = classNames(extClasses && extClasses.tabActive);
    const tabInactiveClass = classNames(extClasses && extClasses.tabInactive);
    const tabContentClass = classNames(extClasses && extClasses.tabContent);

    return (
        <div className={containerClass}>
            <div className={tabContainerClass}>
                {tabsTitles && tabsTitles.available_tabs.map((tab) => (
                    // eslint-disable-next-line react/button-has-type
                    <button
                        onClick={() => setActiveTab(tab)}
                        className={`segmented-tab-tab ${tab === activeTab ? tabActiveClass : tabInactiveClass
                        }`}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            <div className='segmented-tab-line' />
            <div className={tabContentClass}>
                {
                    tabDetails && (
                        <DataGridView
                            tab={tabDetails}
                            extClasses={{
                                title: 'data-grid-box-title data-grid-box-cell-title',
                                gridBox: 'data-grid-box-values data-grid-box-grid',
                                cell: 'data-grid-box-cell',
                                key: 'data-grid-box-cell-title',
                                value: 'data-grid-box-cell-value',
                            }}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default SegmentedTabView;
