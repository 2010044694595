/**
 *
 * NROps
 *
 */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { injectReducer, injectSaga } from '../../utils';
import { LoaderOrError, RenderDocument } from '../../components';

import Navbar from './NRNavbar';
import reducer from './reducer';
import saga from './saga';
import DevActions from '../DevActions';
import { selectNrOpsData } from './selectors';
import { getNROpsData } from './actions';
import { NR_OPS_DEV_ACTION_TYPES } from './constants';

import './style.scss';

const NROps = () => {
    const dispatch = useDispatch();

    const [actorId, setActorId] = useState('');
    const [documentPreFilledValues, setDocumentPreFilledValues] = useState(null);

    const nrOps = useSelector(selectNrOpsData);

    const { nrOpsData, loading, err } = nrOps || {};

    const setDocumentPreFilledValuesObj = (value) => ({
        mergeKey: 'label',
        paramList: [
            { label: 'Actor ID', value },
        ],
    });

    const handleSubmit = () => {
        dispatch(getNROpsData({ actorId }));
        setDocumentPreFilledValues(setDocumentPreFilledValuesObj(actorId));
    };

    const nrInfoSection = ({ crossValidationData: json, images }) => (
        <div className='crossvalidation-data-container'>
            <div className='infoDetails'>Details to be reviewed</div>
            {json && <div className='crossvalidation-data-container-json'>{JSON.stringify(json, null, 4)}</div>}
            {images && (
                <div>
                    {Object.entries(images).map(([key, value]) => ( // Iterate over key-value pairs
                        <div key={key}>
                            <div className='docImageName'>{key}:</div>
                            <div>
                                <RenderDocument
                                    index={0}
                                    data={{
                                        base64String: value,
                                    }}
                                    showNotFound
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className='devActions'>
                <DevActions
                    containerKey='nr-ops-doc-data'
                    actionTypes={NR_OPS_DEV_ACTION_TYPES}
                    preFilledValues={documentPreFilledValues}
                />
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <Navbar
                onSubmit={handleSubmit}
                actorId={actorId}
                setActorId={setActorId}
            />
            {nrOpsData && nrInfoSection(nrOpsData)}
            <LoaderOrError
                loading={loading}
                errorLabel={err}
            />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'nrOps', reducer });
const withSaga = injectSaga({ key: 'nrOps', saga });

export default compose(
    withReducer,
    withSaga,
)(NROps);
