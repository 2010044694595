export const API_ROOT = '/api/v1';
export const API_ROOT_V2 = '/api/v2';
export const API_ROOT_V3 = '/api/v3';

export const AGENT_ROOT = `${API_ROOT}/agents`;
export const AGENT_ROOT_V2 = `${API_ROOT_V2}/agents`;

// multi-role access support
/* agent management api routes */
export const AGENT_ROOT_V3 = `${API_ROOT_V3}/agents`;
export const ACCESS_LEVEL_LIST_ENDPOINT = `${AGENT_ROOT_V3}/access-level-list`;
export const AGENT_TYPE_LIST_ENDPOINT = `${AGENT_ROOT_V3}/type-list`;
export const AGENT_STATUS_LIST_ENDPOINT = `${AGENT_ROOT_V3}/status-list`;
export const GET_AGENT_LIST_ENDPOINT = `${AGENT_ROOT_V3}/list`;
export const GET_LOGGED_IN_AGENT_ENDPOINT = `${AGENT_ROOT_V3}/logged-in`;
/* end agent management api routes */

export const LOGS_ROOT = `${API_ROOT}/logs`;

export const AUTH_ROOT = `${API_ROOT}/auth`;

/* tickets api routes */
export const TICKETS_ROOT = `${API_ROOT}/tickets`;
export const BULK_TICKET_JOB_ENDPOINT = `${TICKETS_ROOT}/bulk-ticket-job`;
export const BULK_TICKET_JOB_LIST_ENDPOINT = `${BULK_TICKET_JOB_ENDPOINT}/list`;
export const BULK_TICKET_JOB_FAILURE_INFO_ENDPOINT = `${BULK_TICKET_JOB_ENDPOINT}/failure-info`;
export const BULK_TICKET_JOB_KILL_ENDPOINT = `${BULK_TICKET_JOB_ENDPOINT}/kill`;
export const TICKET_LIST_FOR_CALL_ENDPOINT = `${TICKETS_ROOT}/ticket-list-for-call`;
export const TICKET_LIST_FOR_SHERLOCK_ENDPOINT = `${TICKETS_ROOT}/support-tickets-for-sherlock`;
const FRESHDESK_ENDPOINT = `${TICKETS_ROOT}/freshdesk`;
export const FRESHDESK_CATEGORIES_ENDPOINT = `${FRESHDESK_ENDPOINT}/categories`;
export const FRESHDESK_DETAILS_ENDPOINT = `${FRESHDESK_ENDPOINT}/details`;
export const GET_USER_TABS_ENDPOINT = `${TICKETS_ROOT}/user-tabs`;
export const GET_USER_TAB_DETAILS_ENDPOINT = `${TICKETS_ROOT}/user-tab-details`;
/* end tickets api routes */

/* issue config api routes */
export const ISSUE_CONFIG_ROOT = `${API_ROOT}/issue-config`;
export const GET_ISSUE_CONFIG_ENDPOINT = `${ISSUE_CONFIG_ROOT}/get`;
export const GET_ISSUE_CONFIG_HISTORY_ENDPOINT = `${ISSUE_CONFIG_ROOT}/history`;
export const UPDATE_ISSUE_CONFIG_ENDPOINT = `${ISSUE_CONFIG_ROOT}/update`;
export const GET_FILTER_OPTIONS_ENDPOINT = `${ISSUE_CONFIG_ROOT}/filter-options`;
/* end issue config api routes */

export const CUSTOMER_AUTH_ROOT = `${API_ROOT}/customer-auth`;

export const PROFILE_ROOT = `${API_ROOT}/profile`;

export const RUDDER_ROOT = `${API_ROOT}/rudder-event`;

export const RECENT_ACTIVITY_ROOT = `${API_ROOT}/recent-activity`;

export const KYC_ROOT = `${API_ROOT}/kyc`;

export const ONBOARDING_ROOT = `${API_ROOT}/onboarding`;
export const SALARY_WHITELIST_B2B_ONBOARDING_ENDPOINT = `${ONBOARDING_ROOT}/whitelist-b2b-users`;

/* accounts api routes */
export const ACCOUNTS_ROOT = `${API_ROOT}/accounts`;
export const ACC_BULK_ACCOUNT_VERIFICATION_ENDPOINT = `${ACCOUNTS_ROOT}/bulk-account-verification`;
export const STORE_CLOSED_ACCOUNT_BALANCES_ENDPOINT = `${ACCOUNTS_ROOT}/closed-account-balances`;
export const STORE_BALANCE_TRANSFER_UTR_ENDPOINT = `${ACCOUNTS_ROOT}/balance-transfer-utr`;
export const SEND_ACCOUNT_STATEMENT = `${ACCOUNTS_ROOT}/send-account-statement`;
/* end accounts api routes */

/* user notifications api routes */
export const GET_AGENT_PROMPTS = `${ACCOUNTS_ROOT}/get-agent-prompts`;
export const SEND_NOTIFICATION_BY_PROMPTS = `${ACCOUNTS_ROOT}/send-notification-by-prompts`;
/* end user notifications api routes */

/* transactions api routes */
export const TRANSACTIONS_ROOT = `${API_ROOT}/transactions`;
export const TXN_ORDER_STATUS_LIST_ENDPOINT = `${TRANSACTIONS_ROOT}/order-status-list`;
export const TXN_PAYMENT_PROTOCOL_LIST_ENDPOINT = `${TRANSACTIONS_ROOT}/payment-protocol-list`;

export const TRANSACTIONS_ROOT_V2 = `${API_ROOT_V2}/transactions`;
/* end transactions api routes */

/* disputes api routes */
export const DISPUTE_ROOT = `${API_ROOT}/dispute`;
export const DISPUTE_DETAILS_ENDPOINT = `${DISPUTE_ROOT}/details`;
export const DISPUTE_ACTOR_DETAILS_ENDPOINT = `${DISPUTE_ROOT}/actor-details`;
export const DISPUTE_CORRESPONDENCE_DETAILS_ENDPOINT = `${DISPUTE_ROOT}/correspondence-details`;
export const DISPUTE_DOCUMENT_ENDPOINT = `${DISPUTE_ROOT}/document`;
export const DISPUTE_MARK_AGAINST_TICKET_ENDPOINT = `${DISPUTE_ROOT}/mark-against-ticket`;
/* end disputes api routes */

export const PAYMENT_INSTRUMENTS_ROOT = `${API_ROOT}/payment`;

export const PAYMENT_INSTRUMENTS_ROOT_V2 = `${API_ROOT_V2}/payment`;

export const PAYMENT_INSTRUMENTS_CARD_ROOT = `${PAYMENT_INSTRUMENTS_ROOT}/card`;

export const PAYMENT_INSTRUMENTS_FOREX_TXN = `${PAYMENT_INSTRUMENTS_ROOT}/forex-txn`;

export const PAYMENT_INSTRUMENTS_CARD_ROOT_V2 = `${PAYMENT_INSTRUMENTS_ROOT_V2}/card`;

export const PAYMENT_INSTRUMENTS_UPI_ROOT = `${PAYMENT_INSTRUMENTS_ROOT}/upi`;

export const PAYMENT_INSTRUMENTS_UPI_ROOT_V2 = `${PAYMENT_INSTRUMENTS_ROOT_V2}/upi`;

export const COMMUNICATIONS_ROOT = `${API_ROOT}/communications`;

export const APP_LOGS_ROOT = `${API_ROOT}/app-logs`;

export const DB_STATES_ROOT = `${API_ROOT}/db-states`;

export const TICKET_SUMMARY_ROOT = `${API_ROOT}/ticket-summary`;

export const DEV_ACTIONS_ROOT = `${API_ROOT}/dev-actions`;

export const LIVENESS_ROOT = `${API_ROOT}/liveness`;

export const LIVENESS_ROOT_V2 = `${API_ROOT_V2}/liveness`;

export const PAYOUT_ROOT = `${API_ROOT}/payout`;

export const WAITLIST_ROOT = `${API_ROOT}/waitlist`;

export const REWARDS_ROOT = `${API_ROOT}/rewards`;

export const FEDERAL_ROOT = `${API_ROOT}/federal`;

export const NON_SENSITIVE_INFO_ROOT = `${API_ROOT}/non-sensitive-info`;

export const FITTT_ROOT = `${API_ROOT}/fittt`;

export const ADMIN_ACTIONS_ROOT = `${API_ROOT}/admin-actions`;

export const REFERRALS_ROOT = `${API_ROOT}/referrals`;

export const RISK_OPS_ROOT = `${API_ROOT}/risk-ops`;
export const RISK_OPS_FORM_ACTION_ROOT = `${RISK_OPS_ROOT}/risk-action`;
export const NR_OPS_PATH = `${RISK_OPS_ROOT}/nrops-info`;

export const CONNECTED_ACCOUNTS_ROOT = `${API_ROOT}/connected-accounts`;

export const US_STOCKS_OPS_ROOT = `${API_ROOT}/us-stocks-ops`;

export const TIERING_ROOT = `${API_ROOT}/tiering`;

/* pre approved loans api routes */
export const PAL_ROOT = `${API_ROOT}/pre-approved-loans`;
export const PAL_USER_INFO_ENDPOINT = `${PAL_ROOT}/user-details`;
export const PAL_LOANS_INFO_ENDPOINT = `${PAL_ROOT}/loan-details`;
export const PAL_LOANS_ADDITIONAL_INFO_ENDPOINT = `${PAL_ROOT}/loan-additional-details`;
export const PAL_LOANS_FORECLOSURE_AMOUNT_ENDPOINT = `${PAL_ROOT}/foreclosure-amount`;

/* loan against mutual funds api routes */
export const LAMF_ROOT = `${API_ROOT}/loan-against-mf`;
export const LAMF_USER_INFO_ENDPOINT = `${LAMF_ROOT}/user-details`;
export const LAMF_LOANS_INFO_ENDPOINT = `${LAMF_ROOT}/loan-details`;

export const SHERLOCK_FEEDBACK_ROOT = `${API_ROOT}/feedback`;

/* wealth api routes */
export const WEALTH_ROOT = `${API_ROOT}/wealth`;
export const WEALTH_P2P_INVESTOR_INFO_ENDPOINT = `${WEALTH_ROOT}/p2p/investor-info`;
export const WEALTH_P2P_INVESTMENT_LIST_ENDPOINT = `${WEALTH_ROOT}/p2p/investment-list`;
export const WEALTH_MF_FILTER_STATUS_ENDPOINT = `${WEALTH_ROOT}/mf-order-filter-status`;
export const WEALTH_US_STOCKS_ENDPOINT = `${WEALTH_ROOT}/us-stocks`;
export const WEALTH_US_STOCKS_ACTIVITY_TYPE = `${WEALTH_US_STOCKS_ENDPOINT}/activity-type`;
export const WEALTH_US_STOCKS_REMITTANCE_TYPE = `${WEALTH_US_STOCKS_ENDPOINT}/remittance-type`;
export const WEALTH_US_STOCKS_REMITTANCES = `${WEALTH_US_STOCKS_ENDPOINT}/remittances`;
export const WEALTH_US_STOCKS_REMITTANCE_ORDER_DETAILS = `${WEALTH_US_STOCKS_ENDPOINT}/remittance-order-details`;
/* end wealth api routes */

/* dynamic config consul api routes */
export const CONSUL_ROOT = `${API_ROOT}/consul`;
export const META_INFO_ENDPOINT = `${CONSUL_ROOT}/meta-info`;
/* end dynamic config consul api routes */

/* instant indexing routes */
export const INSTANT_INDEXING_ROOT = `${API_ROOT}/instant-indexing`;
export const INSTANT_INDEXING_ENDPOINT = INSTANT_INDEXING_ROOT;
export const NOTIFICATION_STATUS_ENDPOINT = `${INSTANT_INDEXING_ROOT}/notification-status`;
/* end instant indexing routes */

/* call api routes */
export const CALL_ROOT = `${API_ROOT}/call`;
export const CALL_LIST_ENDPOINT = `${CALL_ROOT}/list`;
export const CALL_INFO_ENDPOINT = `${CALL_ROOT}/info`;
export const CALL_CREATE_TICKET_ENDPOINT = `${CALL_ROOT}/create-ticket`;
export const CALL_GET_RECORDINGS_ENDPOINT = `${CALL_ROOT}/recordings`;
/* end call api routes */

/* salary account api routes */
export const SALARY_ACCOUNT_ROOT = `${API_ROOT}/salary-account`;
export const SALARY_ACCOUNT_DETAILS_ENDPOINT = `${SALARY_ACCOUNT_ROOT}/account-details`;
export const SALARY_ACCOUNT_MANDATES_ENDPOINT = `${SALARY_ACCOUNT_ROOT}/mandates`;
export const SALARY_ACCOUNT_EXECUTED_MANDATES_ENDPOINT = `${SALARY_ACCOUNT_ROOT}/executed-mandates`;
export const SALARY_ACCOUNT_DETAILS_FOR_DATA_OPS_ENDPOINT = `${SALARY_ACCOUNT_DETAILS_ENDPOINT}/data-ops`;
export const SALARY_ACCOUNT_DETAILS_FOR_AGENTS_ENDPOINT = `${SALARY_ACCOUNT_DETAILS_ENDPOINT}/agents`;
export const SALARY_ACCOUNT_EMPLOYER_DETAILS_ENDPOINT = `${SALARY_ACCOUNT_ROOT}/employer-details`;
export const SALARY_ACCOUNT_ENUMS_ENDPOINT = `${SALARY_ACCOUNT_ROOT}/enums`;
export const SALARY_ACCOUNT_VERIFICATION_REQUESTS_ENDPOINT = `${SALARY_ACCOUNT_ROOT}/verification-requests`;
export const SALARY_ACCOUNT_VERIFICATION_REQUESTS_COUNT_ENDPOINT = `${SALARY_ACCOUNT_VERIFICATION_REQUESTS_ENDPOINT}/count`;
export const SALARY_ACCOUNT_VERIFICATION_EMPLOYER_DETAILS_ENDPOINT = `${SALARY_ACCOUNT_VERIFICATION_REQUESTS_ENDPOINT}/employer-details`;
export const SALARY_ACCOUNT_VERIFICATION_TXN_DETAILS = `${SALARY_ACCOUNT_VERIFICATION_REQUESTS_ENDPOINT}/txn-history`;
export const SALARY_ACCOUNT_VERIFICATION_REQUESTS_FOR_USER_ENDPOINT = `${SALARY_ACCOUNT_VERIFICATION_REQUESTS_ENDPOINT}/user`;
export const SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_ENDPOINT = `${SALARY_ACCOUNT_ROOT}/verification`;
export const SALARY_ACCOUNT_RAISE_VERIFICATION_CASE_DETAILS_ENDPOINT = `${SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_ENDPOINT}/details`;
export const SALARY_ACCOUNT_RAISE_VERIFICATION_ELIGIBLE_TXNS_ENDPOINT = `${SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_ENDPOINT}/eligible-txns`;
export const SALARY_ACCOUNT_RAISE_VERIFICATION_AND_VERIFY_TXN_ENDPOINT = `${SALARY_ACCOUNT_RAISE_VERIFICATION_REQUEST_ENDPOINT}/raise-and-verify-txn`;
export const HEALTH_INSURANCE_ENDPOINT = `${SALARY_ACCOUNT_ROOT}/health-insurance`;
export const SALARY_ACCOUNT_USER_HEALTH_INSURANCE_POLICY_DETAILS_ENDPOINT = `${HEALTH_INSURANCE_ENDPOINT}/user`;
export const SALARY_ACCOUNT_GENERAL_HEALTH_INSURANCE_POLICY_DETAILS_ENDPOINT = `${HEALTH_INSURANCE_ENDPOINT}/general`;
export const SALARY_ACCOUNT_ACTIVATION_STATUS_ENDPOINT = `${SALARY_ACCOUNT_ROOT}/activation-status`;
/* end salary account api routes */

/* banners routes */
export const BANNERS_ROOT = `${API_ROOT}/banners`;
export const BANNER_VISIBILITY_STATE_LIST_ENDPOINT = `${BANNERS_ROOT}/visibility-state-list`;
export const BANNER_LIST_ENDPOINT = `${BANNERS_ROOT}/list`;
export const CUSTOMER_BANNER_LIST_ENDPOINT = `${BANNERS_ROOT}/customer-list`;
/* end banners routes */

/* start user issue info  routes */
export const USER_ISSUE_INFO_ROOT = `${API_ROOT}/user-issue-info`;
/* end user issue info  routes */

/* start US stocks  routes */
export const US_STOCKS_ROOT = `${API_ROOT}/us-stocks`;
export const US_STOCKS_NAV_BAR_ENDPOINT = `${US_STOCKS_ROOT}/nav-bar-entities`;
export const US_STOCKS_FILE_ENTRIES_ENDPOINT = `${US_STOCKS_ROOT}/file-entries`;
export const US_STOCKS_TABLE_FILTERS_ENDPOINT = `${US_STOCKS_FILE_ENTRIES_ENDPOINT}/filters`;
export const US_STOCKS_ADDITIONAL_ACTIONS_ENDPOINT = `${US_STOCKS_ROOT}/additional-actions`;
export const US_STOCKS_GENERATE_FILE_ENDPOINT = `${US_STOCKS_ROOT}/generate-file`;
export const US_STOCKS_ACKNOWLEDGE_FILE_ENTRY_ENDPOINT = `${US_STOCKS_ROOT}/acknowledge-file-entry`;
/* end US stocks  routes */

/* start Transaction risk routes */
export const TRANSACTION_RISK_ROOT = `${API_ROOT}/transaction-risk`;
export const TRANSACTION_RISK_QUEUE_ENDPOINT = `${TRANSACTION_RISK_ROOT}/queue`;
export const TRANSACTION_RISK_QUEUE_FILTER_ENDPOINT = `${TRANSACTION_RISK_ROOT}/queue-filters`;
export const TRANSACTION_RISK_PRIORITIZE_CASE_ENDPOINT = `${TRANSACTION_RISK_ROOT}/prioritized-case`;
export const TRANSACTION_RISK_VIEW_ENDPOINT = `${TRANSACTION_RISK_ROOT}/risk-transactions`;
export const TRANSACTION_RISK_VIEW_FILTER_ENDPOINT = `${TRANSACTION_RISK_ROOT}/risk-transactions-filters`;
export const TRANSACTION_RISK_LIST_ANNOTATION_ENDPOINT = `${TRANSACTION_RISK_ROOT}/case-annotation`;
export const TRANSACTION_RISK_LIST_COMMENTS_ENDPOINT = `${TRANSACTION_RISK_ROOT}/case-comments`;
export const TRANSACTION_RISK_RELATED_CASE_ENDPOINT = `${TRANSACTION_RISK_ROOT}/related-cases`;
export const TRANSACTION_RISK_REVIEW_DETAILS_ENDPOINT = `${TRANSACTION_RISK_ROOT}/transaction-risk-review`;
export const TRANSACTION_RISK_AGGREGATE_DATA = `${TRANSACTION_RISK_ROOT}/aggregate-data`;
/* end Transaction risk routes */

/* credit card api routes */
export const CREDIT_CARD_ROOT = `${API_ROOT}/credit-card`;
export const CREDIT_CARD_ROOT_V2 = `${API_ROOT_V2}/credit-card`;
export const CURRENT_CREDIT_CARD_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/current-card-details`;
export const ALL_CREDIT_CARDS_ENDPOINT = `${CREDIT_CARD_ROOT}/all-cards`;
export const CREDIT_CARD_TRACKING_INFO_ENDPOINT = `${CREDIT_CARD_ROOT}/tracking-info`;
export const CREDIT_CARD_CONTROL_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/control-details`;
export const CREDIT_CARD_USAGE_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/usage-details`;
export const UPDATE_CREDIT_CARD_USAGE_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/update-usage-details`;
export const CREDIT_CARD_ONBOARADING_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/onboarding-details`;
export const FD_CREDIT_CARD_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/fd-credit-card`;
export const CREDIT_CARD_CURRENT_LIMIT_USAGE_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/current-limit-usage-details`;
export const CREDIT_CARD_BILLING_INFO_ENDPOINT = `${CREDIT_CARD_ROOT}/billing-info`;
export const CREDIT_CARD_NEXT_QUESTION_ENDPOINT = `${CREDIT_CARD_ROOT}/next-question`;
export const CREDIT_CARD_RAISE_DISPUTE_ENDPOINT = `${CREDIT_CARD_ROOT}/raise-dispute`;
export const CREDIT_CARD_DISPUTE_INFO_ENDPOINT = `${CREDIT_CARD_ROOT}/dispute-info`;
export const ALL_ACTIVE_LOAN_ACCOUNTS = `${CREDIT_CARD_ROOT}/all-active-loan-accounts`;
export const ALL_CLOSED_LOAN_ACCOUNTS = `${CREDIT_CARD_ROOT}/all-closed-loan-accounts`;
export const LOAN_ACCOUNT_DETAILS = `${CREDIT_CARD_ROOT}/loan-account-details`;
export const ALL_ELIGIBLE_TRANSACTIONS_FOR_EMIS = `${CREDIT_CARD_ROOT}/all-eligible-transactions-for-emis`;
export const LOAN_OFFERS = `${CREDIT_CARD_ROOT}/loan-offers`;
export const BLOCK_CREDIT_CARD_ENDPOINT = `${CREDIT_CARD_ROOT}/block`;
export const SUSPEND_CREDIT_CARD_ENDPOINT = `${CREDIT_CARD_ROOT}/suspend`;
export const CREDIT_CARD_ELIGIBILITY_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/eligibility`;
export const CREDIT_CARD_ONBOARADING_DETAILS_V2_ENDPOINT = `${CREDIT_CARD_ROOT_V2}/onboarding-details`;
export const SELECTED_CREDIT_CARD_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/selected-card-details`;
export const CREDIT_CARD_CURRENT_STAGE_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/current-stage-details`;
export const CREDIT_CARD_NEXT_STAGE_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/next-stage-details`;
export const CREDIT_CARD_MILESTONE_REWARDS_DETAILS_ENDPOINT = `${CREDIT_CARD_ROOT}/milestone-rewards-details`;
/* end credit card routes */

/* credit card transactions api routes */
export const CREDIT_CARD_TRANSACTIONS_ENDPOINT = `${CREDIT_CARD_ROOT}/txns`;
export const FIRST_OR_LAST_SET_CREDIT_CARD_TRANSACTIONS_ENDPOINT = `${CREDIT_CARD_ROOT}/first-or-last-set-txns`;

/* alfred routes */
export const ALFRED_ROUTE = `${API_ROOT}/alfred`;

/* start Create risk ops rule */
export const RULE_QUEUE_ENDPOINT = `${RISK_OPS_ROOT}/rule-queue`;
export const CREATE_RULE_ENDPOINT = `${RISK_OPS_ROOT}/create-rule`;
export const UPDATE_RULE_ENDPOINT = `${RISK_OPS_ROOT}/update-rule`;
export const GET_ALL_TAGS_ENDPOINT = `${RISK_OPS_ROOT}/tags`;
/* end Create risk ops rule */

/* start risk Ops user risk view and AFU history */
export const RISK_OPS_USER_RISK_REVIEW_ENDPOINT = `${RISK_OPS_ROOT}/user-risk-review`;
export const RISK_OPS_AFU_HISTORY_ENDPOINT = `${RISK_OPS_ROOT}/afu-history`;
export const RISK_OPS_SCREENER_CHECK_DETAILS_ENDPOINT = `${RISK_OPS_ROOT}/screener-check-details`;
export const RISK_OPS_ACTIVITY_AREAS = `${RISK_OPS_ROOT}/recent-activity-areas`;
export const RISK_OPS_ACTIVITY = `${RISK_OPS_ROOT}/recent-activity`;

/* start risk Ops user view and AFU history */

/* start User Products */
export const RISK_OPS_USER_PRODUCTS_LIST_ENDPOINT = `${RISK_OPS_ROOT}/user-product-list`;
export const RISK_OPS_USER_PRODUCTS_INFO_ENDPOINT = `${RISK_OPS_ROOT}/user-product-info`;
/* end User Products */

/* start rudder event routes */
export const PUSH_RUDDER_EVENT = `${RUDDER_ROOT}/push-event`;
export const PUSH_PAGE_VIEW_RUDDER_EVENT = `${RUDDER_ROOT}/push-page-view`;
/* end rudder event routes */

/* start recent activity routes */
export const GET_RECENT_ACTIVITY_DROPDOWN_OPTIONS_ENDPOINT = `${RECENT_ACTIVITY_ROOT}/get-dropdown-values`;
export const GET_RECENT_ACTIVITY_ENDPOINT = `${RECENT_ACTIVITY_ROOT}/list-activities`;
export const GET_RECENT_ACTIVITY_DETAILS_ENDPOINT = `${RECENT_ACTIVITY_ROOT}/recent-activity-details`;
/* end recent activity routes */

/* start autopay routes */
export const AUTOPAY_MANDATES_ROOT = `${TRANSACTIONS_ROOT}/mandates`;
export const AUTOPAY_EXISTING_MANDATES_ROOT = `${AUTOPAY_MANDATES_ROOT}/existing`;
export const AUTOPAY_UPCOMING_MANDATES_ROOT = `${AUTOPAY_MANDATES_ROOT}/upcoming`;
/* end autopay routes */

/*  start user response route */
export const USER_REPONSE_CASE_MANAGEMENT = `${RISK_OPS_ROOT}/user-response`;
/* scripts routes */
export const SCRIPTS_ROOT = `${API_ROOT}/scripts`;
export const GET_SCRIPT_ENDPOINT = `${SCRIPTS_ROOT}/script`;
export const GET_SCRIPT_L1_ENDPOINT = `${SCRIPTS_ROOT}/l1`;

/* sop routes */
export const SOP_ROOT = `${API_ROOT}/sop`;
export const GET_PROFILE_ENDPOINT = `${SOP_ROOT}/profile`;
export const GET_SOP_DETAILS_ENDPOINT = `${SOP_ROOT}/details`;
export const GET_SOP_TREE_ENDPOINT = `${SOP_ROOT}/tree`;
export const COMPLETE_SOP_FLOW_ENDPOINT = `${SOP_ROOT}/complete-call`;
export const GET_SEARCH_SOP_ENDPOINT = `${SOP_ROOT}/search`;
export const GET_SEARCH_SOP_META_ENDPOINT = `${GET_SEARCH_SOP_ENDPOINT}/meta`;

/* vkyc routes */
export const VKYC_ROOT = `${API_ROOT}/vkyc`;
export const GET_AVAILABLE_CALL = `${VKYC_ROOT}/available-calls`;
export const INITIATE_CALL = `${VKYC_ROOT}/initiate-call`;
export const HEALTH_CHECK = `${VKYC_ROOT}/health-check`;
export const CONCLUDE_CALL = `${VKYC_ROOT}/conclude-call`;
export const CAPTURE_SCREENSHOT = `${VKYC_ROOT}/capture-screenshot`;
export const GET_USER_LOCATION = `${VKYC_ROOT}/user-location`;
export const PERFORM_CLIENT_ACTION = `${VKYC_ROOT}/perform-action`;
export const ONBOARDING_STAGES = `${VKYC_ROOT}/get-onboarding-stages`;
export const EXTRACT_DOCUMENT_DATA = `${VKYC_ROOT}/extract-document-data`;
export const RECORDING_UPLOAD_ENDPOINT = `${VKYC_ROOT}/aws`;
export const MATCH_SCORE_ENDPOINT = `${VKYC_ROOT}/get-match-score`;
export const END_CALL_ENDPOINT = `${VKYC_ROOT}/end-call`;
export const GENERATE_REPORT_ENDPOINT = `${VKYC_ROOT}/generate-report`;
export const INITIATE_AUDIT = `${VKYC_ROOT}/initiate-audit`;
export const FETCH_AUDIT_REPORT = `${VKYC_ROOT}/vkc-auditor-report`;
export const REVIEW_AUDITOR_REVIEW = `${VKYC_ROOT}/vkc-auditor-review`;
export const GET_AVAILABLE_CALLS_COUNT = `${VKYC_ROOT}/vkc-available-count`;
export const GET_AVAILABLE_AUDIT_REPORT_COUNT = `${VKYC_ROOT}/vkc-auditor-available-count`;

/* issue category routes */
export const ISSUE_CATEGORY_ROOT = `${API_ROOT}/issue-category`;
export const GET_ISSUE_CATEGORY_DETAILS_ENDPOINT = `${ISSUE_CATEGORY_ROOT}/issue-category-details`;

/* logger routes */
export const LOGGER_ROOT = `${API_ROOT}/logger`;
export const LOG_MESSAGE_ENDPOINT = `${LOGGER_ROOT}/log`;

/* stockgaurdian kyc routes */
export const STOCKGAURDIAN_KYC_ROOT = `${API_ROOT}/stockgaurdian-kyc`;
export const REDACT_DOCUMENTS_ENDPOINT = `${STOCKGAURDIAN_KYC_ROOT}/redact-documents`;
export const GET_PRESIGNED_DOCUMENT_URLS_ENDPOINT = `${STOCKGAURDIAN_KYC_ROOT}/get-presigned-document-urls`;
