import {
    GET_NR_OPS_DATA,
    SET_NR_OPS_DATA,
    ERR_NR_OPS_DATA,
} from './constants';

export const getNROpsData = (data) => ({
    type: GET_NR_OPS_DATA,
    data,
});

export const setNROpsData = (data) => ({
    type: SET_NR_OPS_DATA,
    data,
});

export const errGetNROpsData = (data) => ({
    type: ERR_NR_OPS_DATA,
    data,
});
