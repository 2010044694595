import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
    clientApiWrapper, getErrLabel, getErrMsg, getQueryStringFromObject, toastify,
} from '../../utils';
import {
    errGetNROpsData,
    setNROpsData,
} from './actions';
import {
    NR_OPS_PATH,
} from '../../api/routes';
import { GET_NR_OPS_DATA } from './constants';

function* getNROpsData(action) {
    const { actorId } = action.data;
    const queryString = getQueryStringFromObject({ actorId });

    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${NR_OPS_PATH}?${queryString}`,
        );
        yield put(setNROpsData({ nrOpsData: response }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error', true, { position: 'top-center' });
        yield put(errGetNROpsData({ err: getErrMsg(e) }));
    }
}

export default function* getNROpsDataSaga() {
    yield all(
        [
            yield takeLatest(GET_NR_OPS_DATA, getNROpsData),
        ],
    );
}
