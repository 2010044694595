/**
 * @file DataGridView
 * DataGrid component to display data in a grid format. It takes in a tab object and displays the data in a grid format.
 * Each row is a section and each section has a title and a list of key-value pairs.
 */

import React from 'react';
import './style.scss';
import classNames from 'classnames';

const DataGridSegment = (props) => {
    const { title, data, extClasses } = props;
    const titleClass = classNames(extClasses && extClasses.title);
    const gridBoxClass = classNames(extClasses && extClasses.gridBox);
    const cellClass = classNames(extClasses && extClasses.cell);
    const keyClass = classNames(extClasses && extClasses.key);
    const valueClass = classNames(extClasses && extClasses.value);
    return (
        <div className='frWrapper'>
            <div className='p-20'>
                <div className={titleClass}>{title}</div>
            </div>
            <div className={gridBoxClass}>
                {
                    Array.isArray(data?.key_value_pairs?.key_value_list) && data?.key_value_pairs?.key_value_list?.map((item) => (
                        <div className={cellClass}>
                            <div className={keyClass}>{item.key}</div>
                            <div className={valueClass}>
                                {item.value === null || item.value === undefined || item.value === '' ? '-' : `${item.value}`}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

const DataGridView = (props) => {
    const { tab, extClasses } = props;
    return (
        <React.Fragment>
            {
                tab.rows.map((section, index) => (
                    <React.Fragment key={section.label}>
                        <DataGridSegment
                            title={section.label}
                            data={section.detail_value}
                            extClasses={extClasses}
                        />
                        {index !== tab.rows.length - 1 && <div className='line' />}
                    </React.Fragment>
                ))
            }
        </React.Fragment>
    );
};

export default DataGridView;
