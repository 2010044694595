/*
 *
 * NROps constants
 *
 */

export const GET_NR_OPS_DATA = 'app/NROps/GET_NR_OPS_DATA';
export const SET_NR_OPS_DATA = 'app/NROps/SET_NR_OPS_DATA';
export const ERR_NR_OPS_DATA = 'app/NROps/ERR_NR_OPS_DATA';

export const NR_OPS_DEV_ACTION_TYPES = ['PROCESS_NON_RESIDENT_CROSS_VALIDATION_MANUAL_REVIEW'];
