import { fromJS } from 'immutable';

import {
    GET_NR_OPS_DATA,
    SET_NR_OPS_DATA,
    ERR_NR_OPS_DATA,
} from './constants';

const initialState = fromJS({
    nrOpsData: null,
    loading: false,
    err: '',
});

function NROpsReducer(state = initialState, action = '') {
    switch (action.type) {
        case SET_NR_OPS_DATA: {
            const { nrOpsData } = action.data;

            return state
                .set('nrOpsData', fromJS(nrOpsData))
                .set('loading', false)
                .set('err', '');
        }
        case GET_NR_OPS_DATA: {
            return state
                .set('nrOpsData', null)
                .set('loading', true)
                .set('err', '');
        }
        case ERR_NR_OPS_DATA: {
            const { err } = action.data;

            return state
                .set('nrOpsData', null)
                .set('loading', false)
                .set('err', err);
        }

        default:
            return state;
    }
}

export default NROpsReducer;
