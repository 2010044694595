/**
 * @file DocRedaction Actions
 */

import {
    DEQUEUE_DOC_REDACTION_CASE, EXECUTE_DOC_REDACTION, GET_DOC_REDACTION_QUEUE, SET_DOC_REDACTION_CUR_CASE,
    SET_DOC_REDACTION_CUR_VIEW, SET_DOC_REDACTION_QUEUE_FILTERS, GET_PRESIGNED_DOCUMENT_URLS, SET_PRESIGNED_DOCUMENT_URLS,
    LOG_ACTION,
} from './constants';

export const getDocRedactionQueueCasesAction = (data) => ({
    type: GET_DOC_REDACTION_QUEUE,
    data,
});

export const executeDocRedactionAction = (data) => ({
    type: EXECUTE_DOC_REDACTION,
    data,
});

export const dequeueDocRedactionCaseAction = (data) => ({
    type: DEQUEUE_DOC_REDACTION_CASE,
    data,
});

export const setDocRedactionCurViewAction = (data) => ({
    type: SET_DOC_REDACTION_CUR_VIEW,
    data,
});

export const setDocRedactionCurCaseAction = (data) => ({
    type: SET_DOC_REDACTION_CUR_CASE,
    data,
});

export const setDocRedactionQueueFiltersAction = (data) => ({
    type: SET_DOC_REDACTION_QUEUE_FILTERS,
    data,
});

export const getPresignedDocumentUrl = (data) => ({
    type: GET_PRESIGNED_DOCUMENT_URLS,
    data,
});

export const setPresignedDocUrlAction = (data) => ({
    type: SET_PRESIGNED_DOCUMENT_URLS,
    data,
});

export const logAction = (data) => ({
    type: LOG_ACTION,
    data,
});
