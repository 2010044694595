/**
 * @file SherlockV2 contains rudder event names for Sherlock V2 Agent level
 */

const SherlockV2TabActive = 'SherlockV2TabActive';
const SherlockV2TabInActive = 'SherlockV2TabInActive';
const SherlockV2TabOpen = 'SherlockV2TabOpen';
const SherlockV2TabClosed = 'SherlockV2TabClosed';
const ClickedSherlockV2CallsTableButton = 'ClickedSherlockV2CallsTableButton';
const ClickedSherlockV2IKnowUserTicketIdButton = 'ClickedSherlockV2IKnowUserTicketIdButton';
const SelectedSherlockV2Call = 'SelectedSherlockV2Call';
const SelectedSherlockV2Ticket = 'SelectedSherlockV2Ticket';
const CreatedNewSherlockV2Ticket = 'CreatedNewSherlockV2Ticket';
const ViewedSherlockV2TicketsPage = 'ViewedSherlockV2TicketsPage';
const ViewedSherlockV2UserDetailsPage = 'ViewedSherlockV2UserDetailsPage';
const SelectedSherlockV2RecentActivity = 'SelectedSherlockV2RecentActivity';
const ViewedSherlockV2RecentActivityDetails = 'ViewedSherlockV2RecentActivityDetails';
const SelectedSherlockV2RecentQuery = 'SelectedSherlockV2SOPRecentQuery';

export {
    SherlockV2TabActive,
    SherlockV2TabInActive,
    SherlockV2TabOpen,
    SherlockV2TabClosed,
    ClickedSherlockV2CallsTableButton,
    ClickedSherlockV2IKnowUserTicketIdButton,
    SelectedSherlockV2Call,
    SelectedSherlockV2Ticket,
    CreatedNewSherlockV2Ticket,
    ViewedSherlockV2TicketsPage,
    ViewedSherlockV2UserDetailsPage,
    SelectedSherlockV2RecentActivity,
    ViewedSherlockV2RecentActivityDetails,
    SelectedSherlockV2RecentQuery,
};
