/**
 * Home - This component is the main component for the Home page
 */
import React, { memo, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
    Button, FormWithSingleInput, LoaderOrError, Modal, Table,
} from '../../components';
import { pushRudderEvent, RudderEvent } from '../../rudderEvents';
import { MetaInfoContext, UserDetailsContext } from '../../context';
import { injectReducer, injectSaga } from '../../utils';

import { VIEW_SOP_USER_DETAILS_PATH, VIEW_SOP_USER_TICKETS_PATH } from '../AppV2/routes';
import { setCallerIdAction, setTicketIdAction } from '../SOP/actions';
import { selectCreatedTicket } from '../AppV2/selectors';

import { selectHome } from './selector';
import { getCallsAction } from './actions';
import CreateNewTicketModal from './CreateNewTicketModal';
import reducer from './reducer';
import saga from './saga';
import './style.scss';

const customModalStyles = {
    content: {
        padding: 32,
        width: '400px',
    },
};

const Home = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { basicInfo: agentInfo } = useContext(UserDetailsContext);
    const metaInfo = useContext(MetaInfoContext);
    const {
        tickets: {
            reasonInfo: {
                callListTable,
            },
        },
        componentVersions: {
            callHandlingV2,
        },
    } = metaInfo;

    const homeDetails = useSelector(selectHome, shallowEqual);
    const createdTicketForCall = useSelector(selectCreatedTicket, shallowEqual);

    const {
        loading: callListLoading, data: callsTable, err: callListErr,
    } = homeDetails || {};
    const {
        loading: createTicketLoading, data: createdTicket, err: createTicketErr,
    } = createdTicketForCall || {};

    const loading = callListLoading || createTicketLoading;
    const err = createTicketErr || callListErr;

    const [callSelected, setCallSelected] = useState();
    const [ticketIdInput, setTicketIdInput] = useState(false);
    const [ticketId, setTicketId] = useState('');
    const [reasonModalVisibility, setReasonModalVisibility] = useState(false);
    const [loadingText, setLoadingText] = useState('');

    const getCalls = (params = {}) => dispatch(getCallsAction({
        ozonetelId: agentInfo?.ozonetelId, key: 'data', clearData: true, ...params,
    }));

    useEffect(() => {
        if (callHandlingV2 === 1 && agentInfo && agentInfo?.ozonetelId && !callsTable) {
            getCalls();
        }
    }, [agentInfo?.ozonetelId]);

    const renderActionsForCallList = (item) => (
        <div className='frwpWrapper flex-justify-center'>
            {
                item?.actions && Array.isArray(item?.actions) && item?.actions?.map((action) => (
                    <div className='p-5'>
                        <Button
                            v2
                            primary
                            label={action.label}
                            extClasses='home-button'
                            onClick={() => {
                                pushRudderEvent(RudderEvent.SherlockV2.ClickedSherlockV2CallsTableButton, agentInfo.emailId, { call: item });
                                setCallSelected(item);
                                if (action.label === 'Create Ticket') {
                                    setReasonModalVisibility(true);
                                }
                            }}
                        />
                    </div>
                ))
            }
        </div>
    );

    const handleInputChange = (e) => {
        setTicketId(e.target.value);
    };

    if (callHandlingV2 !== 1) {
        return (
            <div className='tickets'>
                <div className='tickets-cr frccWrapper'>Coming Soon</div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className='home fccWrapper'>
                <div className='frcsbWrapper'>
                    <div className='sop-heading'>Your calls</div>
                    <Button
                        v2
                        primary
                        label='Refresh'
                        extClasses='home-button'
                        onClick={getCalls}
                    />
                </div>
                <Table
                    v2
                    rowDataVersion={2}
                    labelData={callsTable?.columnData}
                    contentData={callsTable?.rowData}
                    renderActionableColumn={renderActionsForCallList}
                    prevToken={callsTable?.prevToken}
                    nextToken={callsTable?.nextToken}
                    paginatedClickHandler={({ prev, next }) => getCalls({ prev, next })}
                    nsrLabel='No calls found for the agent!'
                    extClasses={{
                        container: 'home-table',
                        actionableColumn: 'home-table-column-large',
                        ticketId: {
                            label: 'home-table-column-short',
                            content: 'home-table-column-short',
                        },
                        number: {
                            label: 'home-table-column-short',
                            content: 'home-table-column-short',
                        },
                    }}
                />
                <Button
                    v2
                    primary
                    extClasses='home-button'
                    label={'I know user\'s ticket id'}
                    onClick={() => {
                        pushRudderEvent(RudderEvent.SherlockV2.ClickedSherlockV2IKnowUserTicketIdButton, agentInfo.emailId, {});
                        setTicketIdInput(true);
                    }}
                />
            </div>
            <Modal
                visible={!!callSelected && !reasonModalVisibility}
                onClose={() => setCallSelected()}
            >
                <div className='fccWrapper'>
                    <div className='home-modal-text'>You have an ongoing call from</div>
                    <div className='home-modal-text-bold'>{callSelected?.number[callSelected?.number.value_v2]}</div>
                    <div className='home-modal-text-small'>{callSelected?.time[callSelected?.time.value_v2]}</div>
                </div>
                <div className='frcWrapper flex-justify-center'>
                    <div className='home-modal-button p-5'>
                        <Button
                            v2
                            secondary
                            label='Ignore'
                            extClasses='home-button'
                            onClick={() => setCallSelected()}
                        />
                    </div>
                    <div className='home-modal-button p-5'>
                        <Button
                            v2
                            primary
                            label='Accept'
                            extClasses='home-button'
                            onClick={() => {
                                const meta = callSelected?.meta && JSON.parse(callSelected?.meta);
                                pushRudderEvent(RudderEvent.SherlockV2.SelectedSherlockV2Call, agentInfo.emailId, { phoneNumber: meta?.phoneNumber });
                                dispatch(setCallerIdAction({ path: ['callerId'], value: meta?.phoneNumber }));
                                if (meta?.ticketId && meta?.ticketId !== '0') {
                                    dispatch(setTicketIdAction({ path: ['ticketId'], value: meta?.ticketId }));
                                    history.push({
                                        pathname: VIEW_SOP_USER_DETAILS_PATH(
                                            { ticketId: meta?.ticketId },
                                        ),
                                    });
                                    return;
                                }
                                history.push({
                                    pathname: VIEW_SOP_USER_TICKETS_PATH,
                                    state: { ucid: meta?.ucid },
                                });
                                setCallSelected();
                            }}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                visible={ticketIdInput}
                modalStyles={customModalStyles}
                onClose={() => setTicketIdInput(false)}
            >
                <div className='fccWrapper flex-justify-center'>
                    <FormWithSingleInput
                        label='Ticket ID'
                        labelId='ticket'
                        input={{
                            value: ticketId,
                            pattern: '\\d+',
                            onChange: handleInputChange,
                        }}
                        onFormSubmit={() => {
                            dispatch(setCallerIdAction({ path: ['callerId'], value: '' }));
                            history.push({
                                pathname: VIEW_SOP_USER_DETAILS_PATH({ ticketId }),
                            });
                        }}
                    />
                    <div className='frcWrapper flex-justify-center'>
                        <div className='home-modal-button p-5'>
                            <Button
                                v2
                                secondary
                                extClasses='home-button'
                                label='Close'
                                onClick={() => setTicketIdInput(false)}
                            />
                        </div>
                        <div className='home-modal-button p-5'>
                            <Button
                                v2
                                primary
                                label='Submit'
                                extClasses='home-button'
                                onClick={() => {
                                    pushRudderEvent(RudderEvent.SherlockV2.SelectedSherlockV2Ticket, agentInfo.emailId, { ticketId });
                                    dispatch(setCallerIdAction({ path: ['callerId'], value: '' }));
                                    history.push({
                                        pathname: VIEW_SOP_USER_DETAILS_PATH({ ticketId }),
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <CreateNewTicketModal
                reasonList={callListTable}
                setLoadingText={setLoadingText}
                ucid={(callSelected && callSelected?.meta) ? JSON.parse(callSelected?.meta)?.ucid || '' : ''}
                reasonModalVisibility={reasonModalVisibility}
                createdTicket={createdTicket}
                onResetReasonState={() => {
                    setReasonModalVisibility(false);
                    setCallSelected();
                }}
            />
            <LoaderOrError loadingText={loadingText} loading={loading} errorLabel={err} />
        </React.Fragment>
    );
};

const withReducer = injectReducer({ key: 'home', reducer });
const withSaga = injectSaga({ key: 'home', saga });

export default compose(
    withReducer,
    withSaga,
)(memo(Home));
