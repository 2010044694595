/**
 * @file Manages the reducer state & functions for Scripts component
 */
import { fromJS } from 'immutable';
import {
    ADD_SOP_NODE_RESPONSE, COMPLETED_SOP_FLOW, COMPLETE_SOP_FLOW, CONDITION_TYPE, DEL_SOP_NODE, ERR_IN_COMPLETING_SOP_FLOW,
    ERR_SOP_DETAILS, ERR_SOP_NODE, GET_SOP_DETAILS, GET_SOP_NODE,
    SET_CALLER_ID, SET_SOP_DETAILS, SET_SOP_NODE, SET_TICKET_ID, STEP_TYPE,
    GET_USER_TICKETS, SET_USER_TICKETS, ERR_USER_TICKETS,
    GET_RECENT_ACTIVITIES, SET_RECENT_ACTIVITIES, ERR_RECENT_ACTIVITIES,
    GET_RECENT_ACTIVITY_DETAILS, SET_RECENT_ACTIVITY_DETAILS, ERR_RECENT_ACTIVITY_DETAILS,
    GET_ISSUE_CATEGORY_DETAILS, SET_ISSUE_CATEGORY_DETAILS, ERR_ISSUE_CATEGORY_DETAILS,
    GET_AUTH_FACTOR, SET_AUTH_FACTOR, ERR_AUTH_FACTOR, ATTACH_TICKET_TO_CALL, ATTACHED_TICKET_TO_CALL, ERR_IN_ATTACHING_TICKET_TO_CALL,
    GET_RECENT_QUERIES, SET_RECENT_QUERIES, ERR_RECENT_QUERIES,
    GET_USER_TABS, ERR_USER_TABS, SET_USER_TABS,
    GET_USER_TAB_DETAILS, SET_USER_TAB_DETAILS, ERR_USER_TAB_DETAILS,
} from './constants';

const initialState = fromJS({
    ticketId: 0,
    callerId: '',
    authScreen: {
        loading: false,
        err: '',
        data: null,
    },
    userTickets: {
        loading: false,
        err: '',
        data: null,
        attachCall: {
            loading: false,
            err: '',
        },
    },
    userTabs: {
        loading: false,
        err: '',
        data: null,
    },
    userActivities: {
        loading: false,
        err: '',
        data: null,
        activityDetails: {
            loading: false,
            err: '',
            data: null,
        },
        issueCategoryDetails: {
            loading: false,
            err: '',
            data: null,
        },
    },
    userTabDetails: {
        loading: false,
        err: '',
        data: null,
    },
    userQueries: {
        loading: false,
        err: '',
        data: null,
    },
    tree: {
        loading: false,
        err: '',
        data: null,
        nodes: {
            loading: false,
            err: '',
            data: [],
        },
    },
});

function sopReducer(state = initialState, action = {}) {
    switch (action.type) {
        // Getter changes
        case ATTACH_TICKET_TO_CALL:
        case GET_RECENT_QUERIES:
        case GET_AUTH_FACTOR:
        case GET_RECENT_ACTIVITY_DETAILS:
        case GET_ISSUE_CATEGORY_DETAILS:
        case GET_RECENT_ACTIVITIES:
        case GET_USER_TICKETS:
        case COMPLETE_SOP_FLOW:
        case GET_SOP_DETAILS:
        case GET_SOP_NODE:
        case GET_USER_TABS:
        case GET_USER_TAB_DETAILS: {
            const { screenKey, clearData } = action.data;
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const values = {
                    loading: true,
                    err: '',
                };
                if (clearData) values.data = null;
                return state.mergeIn(key, values);
            }
            return state;
        }
        // Setter merge changes
        case ATTACHED_TICKET_TO_CALL:
        case SET_RECENT_QUERIES:
        case SET_AUTH_FACTOR:
        case SET_RECENT_ACTIVITY_DETAILS:
        case SET_ISSUE_CATEGORY_DETAILS:
        case SET_USER_TICKETS:
        case COMPLETED_SOP_FLOW:
        case SET_SOP_DETAILS:
        case SET_USER_TABS:
        case SET_USER_TAB_DETAILS: {
            const { screenKey, value } = action.data;
            let key;
            if (screenKey) {
                let values = {
                    loading: false,
                    err: '',
                };
                if (value) {
                    values = {
                        ...values,
                        ...value,
                    };
                }
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                return state.mergeIn(key, values);
            }

            return state;
        }

        // Setter non-api changes
        case SET_CALLER_ID:
        case SET_TICKET_ID: {
            const { path, value } = action.data;
            let key = path;
            if (key && !Array.isArray(key)) key = [key];
            return state.setIn(key, value);
        }

        // Err Changes
        case ERR_IN_ATTACHING_TICKET_TO_CALL:
        case ERR_RECENT_QUERIES:
        case ERR_AUTH_FACTOR:
        case ERR_RECENT_ACTIVITY_DETAILS:
        case ERR_ISSUE_CATEGORY_DETAILS:
        case ERR_RECENT_ACTIVITIES:
        case ERR_USER_TICKETS:
        case ERR_IN_COMPLETING_SOP_FLOW:
        case ERR_SOP_DETAILS:
        case ERR_SOP_NODE:
        case ERR_USER_TABS:
        case ERR_USER_TAB_DETAILS: {
            const { screenKey, err } = action.data;
            let key;
            if (screenKey) {
                key = [screenKey];
                if (Array.isArray(screenKey)) {
                    key = screenKey;
                }
                const values = {
                    loading: false,
                    err,
                };
                return state.mergeIn(key, values);
            }
            return state;
        }

        case SET_SOP_NODE: {
            const { value: node } = action.data;
            const nodes = (state && state.getIn(['tree', 'nodes', 'data'])) || [];
            const {
                errorRpcs = [], isUpdated, sections: newSections, nodeId, requiredFieldInput = [], nextNode: newNextNode,
            } = (node && node?.step) || {};
            let newNodes = nodes;
            const { stepType, step } = (newNodes[newNodes.length - 1]) || {};
            if (isUpdated && stepType === STEP_TYPE.infoDisplay && step && step?.sections) {
                const { sections, nextNode: oldNextNode } = step || {};
                step.requiredFieldInput = requiredFieldInput;
                step.errorRpcs = errorRpcs;
                // eslint-disable-next-line no-unused-expressions
                newSections && Array.isArray(newSections) && newSections.forEach((newSection) => {
                    const { label, values: newValues } = newSection;
                    const oldSectionIndex = sections && Array.isArray(sections) && sections.findIndex((section) => section?.label === label);
                    const oldSection = sections[oldSectionIndex];
                    const { values: oldValues } = oldSection || {};
                    // eslint-disable-next-line no-unused-expressions
                    newValues && Array.isArray(newValues) && newValues.forEach((newLabelValue) => {
                        const { label: fieldLabel } = newLabelValue;
                        const oldValuesIndex = oldValues
                            && Array.isArray(oldValues)
                            && oldValues.findIndex((oldLabelValue) => oldLabelValue?.label === fieldLabel);
                        oldValues[oldValuesIndex] = newLabelValue;
                    });
                });
                if (oldNextNode.next_step_condition_type === CONDITION_TYPE.complex) {
                    step.nextNode = newNextNode;
                }
            } else if (step?.nodeId === nodeId) {
                newNodes[newNodes.length - 1] = node;
            } else {
                newNodes = [...newNodes, node];
            }

            let values = {
                loading: false,
                err: '',
            };
            values = {
                ...values,
                data: newNodes,
            };
            return state.mergeIn(['tree', 'nodes'], values);
        }

        case ADD_SOP_NODE_RESPONSE: {
            const { stepIndex, value } = action.data;
            const nodes = (state && state.getIn(['tree', 'nodes', 'data'])) || [];
            if (nodes && nodes[stepIndex]) {
                nodes[stepIndex].response = value;
            }
            let values = {
                loading: false,
                err: '',
            };
            values = {
                ...values,
                data: nodes,
            };
            return state.mergeIn(['tree', 'nodes'], values);
        }

        case DEL_SOP_NODE: {
            const { noOfElements } = action.data;
            const nodes = (state && state.getIn(['tree', 'nodes', 'data'])) || [];
            const newNodes = nodes && Array.isArray(nodes) && nodes.slice(0, (-1 * noOfElements) || -1);
            let values = {
                loading: false,
                err: '',
            };
            values = {
                ...values,
                data: newNodes,
            };
            return state.mergeIn(['tree', 'nodes'], values);
        }

        case SET_RECENT_ACTIVITIES: {
            let { value } = action.data;
            const activityRows = (state && state.getIn(['userActivities', 'data', 'activityDetails', 'rowData'])) || [];
            const { activityDetails } = value.data || {};
            const { rowData: newRows } = activityDetails || {};
            if (activityDetails && newRows) {
                value = {
                    data: {
                        ...value.data,
                        activityDetails: {
                            ...activityDetails,
                            rowData: [...activityRows, ...newRows],
                        },
                    },
                };
            }
            let values = {
                loading: false,
                err: '',
            };
            values = {
                ...values,
                ...value,
            };
            return state.mergeIn(['userActivities'], values);
        }
        default:
            return state;
    }
}

export default sopReducer;
