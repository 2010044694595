/**
 * @file useWebSocket.js - This file contains a custom React hook for managing a WebSocket connection.
 * It provides functionality to establish a WebSocket connection, send and receive messages, and handle errors.
 * Example Usage: const [connectionOpen, response, send, err] = useWebSocket();
 */

import { useEffect, useRef, useState } from 'react';
import { getErrLabel, toastify } from '../utils';

const protocol = window.location.hostname === 'localhost' ? 'ws' : 'wss';
const proxyRoute = window.location.hostname === 'localhost' ? '/socket' : '';
const { host } = window.location;

const WS_URL = `${protocol}://${host}${proxyRoute}`;

const useWebSocket = () => {
    const [connectionOpen, setConnectionOpen] = useState(false);
    const [response, setResponse] = useState(null);
    const [err, setErr] = useState(null);
    const [webSocketState, setWebSocketState] = useState(WebSocket.CLOSED);

    let messageTimeout;

    const ws = useRef(null);

    useEffect(() => {
        const socket = new WebSocket(WS_URL);

        socket.addEventListener('open', () => {
            setWebSocketState(WebSocket.OPEN);
            startMessageTimeout();
            setConnectionOpen(true);
        });
        socket.addEventListener('close', () => {
            setConnectionOpen(false);
            clearTimeout(messageTimeout);
        });

        const startMessageTimeout = () => {
            clearTimeout(messageTimeout);
            messageTimeout = setTimeout(() => {
                setErr(new Error('Server Side Timeout'));
                if (socket.readyState === WebSocket.OPEN) {
                    socket.close();
                    setWebSocketState(WebSocket.CLOSED);
                }
            }, 10000);
        };

        socket.addEventListener('message', (event) => {
            try {
                clearTimeout(messageTimeout); // Reset timeout on message received
                startMessageTimeout();
                const data = JSON.parse(event.data);
                if (data.type === 'error') {
                    throw new Error(data.error);
                } else if (data.type === 'end') {
                    socket.close();
                } else {
                    setResponse(data);
                }
            } catch (e) {
                if (typeof socket?.close === 'function') socket.close();
                console.error('WebSocket error:', e);
                setErr(new Error('Connection with Server Lost'));
                const errLabel = getErrLabel(e) || e;
                if (typeof errLabel === 'string') toastify(errLabel, 'error');
            }
        });
        socket.addEventListener('error', (error) => {
            clearTimeout(messageTimeout);
            console.error('WebSocket error:', error);
            setErr(error);
        });

        ws.current = socket;

        // Network event handlers
        const handleOffline = () => {
            clearTimeout(messageTimeout);
            setErr(new Error('Internet connection lost'));
            setConnectionOpen(false);
            if (socket.readyState === WebSocket.OPEN) {
                socket.close();
                setWebSocketState(WebSocket.CLOSED);
            }
        };

        const handleOnline = () => {
            clearTimeout(messageTimeout);
            // Reconnect or take appropriate action when online
            const newSocket = new WebSocket(WS_URL);
            ws.current = newSocket;
            setConnectionOpen(true);
        };

        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        return () => {
            socket.close();
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        };
    }, []);

    // bind is needed to make sure `send` references correct `this`
    return [connectionOpen, response, ws.current?.send.bind(ws.current), err, webSocketState];
};

export default useWebSocket;
