/**
 * @file Manages the sagas for AppV2 component
 */

import {
    all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
    clientApiWrapper, getErrLabel, getErrMsg, toastify,
} from '../../utils';
import {
    CALL_CREATE_TICKET_ENDPOINT, GET_SCRIPT_L1_ENDPOINT,
} from '../../api/routes';
import {
    GET_L1_CATEGORIES, CREATE_TICKET_FOR_CALL, ERR_CREATED_TICKET_FOR_CALL, SET_CREATED_TICKET_FOR_CALL,
} from './constants';
import {
    errL1CategoriesAction, setL1CategoriesAction,
} from './actions';

function* getL1CategoriesSaga() {
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.get],
            `${GET_SCRIPT_L1_ENDPOINT}`,
        );
        yield put(setL1CategoriesAction({ screenKey: ['L1Categories'], value: { L1List: response } }));
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        yield put(errL1CategoriesAction({ screenKey: ['L1Categories'], err: getErrMsg(e) }));
    }
}

function* createTicketForCallSaga(action) {
    const { ucid, reason, resolve } = action.data;
    try {
        const response = yield call(
            [clientApiWrapper, clientApiWrapper.post],
            CALL_CREATE_TICKET_ENDPOINT,
            { ucid, reason },
        );

        yield put({
            type: SET_CREATED_TICKET_FOR_CALL,
            data: { screenKey: ['createTicketForCall'], value: { data: response?.createdTicketInfoForCall } },
        });
        if (resolve) yield call(resolve, { ticketId: response?.createdTicketInfoForCall?.id });
    } catch (e) {
        const err = getErrLabel(e);
        toastify(err, 'error');
        const { response } = e || {};
        const { errMsgList, hasErrMsg } = response?.createdTicketInfoForCall || {};
        yield put({
            type: ERR_CREATED_TICKET_FOR_CALL,
            data: {
                screenKey: ['createTicketForCall'],
                err: hasErrMsg ? (Array.isArray(errMsgList) && errMsgList.toString()) || e.message : e.message,
            },
        });
    }
}

export default function* AppSaga() {
    yield all(
        [
            yield takeLatest(GET_L1_CATEGORIES, getL1CategoriesSaga),
            yield takeLatest(CREATE_TICKET_FOR_CALL, createTicketForCallSaga),
        ],
    );
}
